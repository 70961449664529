import React, { useRef, useState, useEffect, lazy } from 'react';
import { Box, Flex, HStack, VStack, Text, AspectRatio } from '@chakra-ui/react';
import { AddIcon, MinusIcon } from '@components/atoms/icons';
import MapImage from '@components/atoms/MapImage';
import MapMarker from '@components/molecules/MapMarker';
import SuspenseLoader from '@components/atoms/SuspenseLoader';
import SuspenseHelper from '@components/atoms/SuspenseHelper';

const DraggableBlock = lazy(
  () => import(`@components/atoms/DraggableBlock`)
  // eslint-disable-next-line function-paren-newline
);

/**
 * Gets the difference between two point values
 * @param {Object} values - Point values to compare against
 * @param {Number} values.x1 - Base point along x axis
 * @param {Number} values.x2 - Comparison point along x axis
 * @param {Number} values.y1 - Base point along y axis
 * @param {Number} values.x2 - Comparison point along y axis
 * @returns {Object} Returns x and y values after comparison (rounds to positive values)
 */
const getDifference = ({ x1, x2, y1, y2 }) => {
  return {
    x: Math.abs(x1 - x2),
    y: Math.abs(y1 - y2)
  };
};

/**
 * Calculates the map, its boundaries, padding and center
 * @param {Number} mapWidth - Current map width
 * @param {Number} mapHeight - Current map height
 * @param {Object} boundingRef - Reference to wrapper around draggable area
 * @param {Object} draggableRef - Reference to draggable area
 * @returns {Object} - Calculated values for map based on current screen size
 */
const calculateMap = (mapWidth, mapHeight, boundingRef, draggableRef) => {
  if (boundingRef === null || draggableRef === null) {
    return {
      x: 0,
      y: 0,
      center: {
        x: 0,
        y: 0
      },
      padding: {
        x: 0,
        y: 0
      }
    };
  }

  const { width, height } = boundingRef?.getBoundingClientRect();
  const { width: draggableWidth, height: draggableHeight } =
    draggableRef?.getBoundingClientRect();

  const { x: xPadding, y: yPadding } = getDifference({
    x1: width,
    x2: draggableWidth,
    y1: height,
    y2: draggableHeight
  });

  const { x, y } = getDifference({
    x1: mapWidth,
    x2: width,
    y1: mapHeight,
    y2: height
  });

  const centerScreenX = width / 2;
  const centerMapX = mapWidth / 2;

  const centerScreenY = height / 2;
  const centerMapY = mapHeight / 2;

  return {
    x,
    y,
    center: {
      x: centerScreenX - centerMapX,
      y: centerScreenY - centerMapY
    },
    padding: {
      x: xPadding,
      y: yPadding
    }
  };
};

const MapShell = ({ mapWidth, mapHeight, markers, boundingRef, zoom }) => {
  const [draggableBounds, setDraggableBounds] = useState({ x: 0, y: 0 });
  const draggableRef = useRef(null);
  const pinsRef = useRef(null);

  const [boxCalc, setBoxCalc] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const width = draggableRef.current?.getBoundingClientRect().width || 0;
    const height = draggableRef.current?.getBoundingClientRect().height || 0;
    const maxWidth = boundingRef.current?.offsetWidth || 0;
    const maxHeight = boundingRef.current?.offsetHeight || 0;

    if (draggableBounds.x === 0 || draggableBounds.y === 0) {
      return;
    }

    setBoxCalc({
      x: -draggableBounds.x / 2,
      y: -draggableBounds.y / 2
    });

    setDraggableBounds({
      x: Math.abs(maxWidth - width / zoom),
      y: Math.abs(maxHeight - height / zoom)
    });
  }, [
    setDraggableBounds,
    setBoxCalc,
    zoom,
    boundingRef,
    draggableBounds.x,
    draggableBounds.y
  ]);

  /* Get the width of the map,
   * Get the width of the container,
   * map width - container width = offset */
  const [autoCenter, setAutoCenter] = useState(true);
  const [mapCenter, setMapCenter] = useState({ x: 0, y: 0 });
  const [mapBounds, setMapBounds] = useState({
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  });

  const handleInteractionStarted = () => setAutoCenter(false);

  useEffect(() => {
    const recalibrate = () => {
      const { x, y, padding, center } = calculateMap(
        mapWidth,
        mapHeight,
        boundingRef.current,
        draggableRef.current
      );

      const px = padding.x / 2;
      const py = padding.y / 2;

      setMapBounds({
        top: -y - py,
        right: px,
        bottom: py,
        left: -x - px
      });

      if (autoCenter === true) {
        setMapCenter(center);
      }
    };

    recalibrate();

    window.addEventListener(`resize`, recalibrate);
    return () => window.removeEventListener(`resize`, recalibrate);
  }, [zoom, boundingRef, autoCenter, mapWidth, mapHeight]);

  return (
    <DraggableBlock
      position={autoCenter ? mapCenter : null}
      cancel=".map-marker, .map-marker-btn, .chakra-button"
      bounds={mapBounds}
      onStart={handleInteractionStarted}>
      <AspectRatio
        pos="relative"
        ratio={mapWidth / mapHeight}
        w={`${mapWidth}px`}>
        <>
          <Box
            transform={`scale(${zoom}) translate(${boxCalc.x}px, ${boxCalc.y}px)`}
            cursor="move"
            pos="absolute"
            ref={draggableRef}>
            <MapImage width="100%" />
          </Box>
          <Box
            ref={pinsRef}
            pos="absolute"
            transform={`scale(${zoom}) translate(${boxCalc.x}px, ${boxCalc.y}px)`}>
            {markers.map((marker) => {
              return (
                <MapMarker
                  marker={marker}
                  zoom={zoom}
                  key={`${marker.title}-${marker.latitude}-${marker.longitude}`}
                />
              );
            })}
          </Box>
        </>
      </AspectRatio>
    </DraggableBlock>
  );
};

const InteractiveMap = ({ data: { markers } }) => {
  const mapWidth = 1378;
  const mapHeight = 772;

  const boundingRef = useRef(null);
  const [zoom, setZoom] = useState(1);

  return (
    <Box
      maxHeight={`${mapHeight}px`}
      maxWidth={`${mapWidth}px`}
      height={{ base: `90vh`, md: `100vh` }}
      mb="24"
      pos="relative"
      overflow="hidden"
      ref={boundingRef}
      w="100%"
      mx="auto">
      <SuspenseHelper fallback={<SuspenseLoader />}>
        <MapShell
          mapWidth={mapWidth}
          mapHeight={mapHeight}
          markers={markers}
          boundingRef={boundingRef}
          zoom={zoom}
        />
      </SuspenseHelper>
      <Flex
        position="absolute"
        bottom="8"
        left={{ base: `1.5`, lg: `8` }}
        alignItems="center"
        h="32">
        <VStack mr="2rem" gap="0.75rem">
          <AddIcon
            backgroundColor="primary.dark-blue"
            color="white"
            w="3rem"
            h="3rem"
            p="0.75rem"
            borderRadius="lg"
            _hover={{ backgroundColor: `secondary.pink!important` }}
            onClick={() => {
              if (zoom < 1.8) setZoom(Math.round((zoom + 0.2) * 100) / 100);
            }}
          />
          <MinusIcon
            backgroundColor="primary.dark-blue"
            color="white"
            w="3rem"
            h="3rem"
            p="0.75rem"
            borderRadius="lg"
            _hover={{ backgroundColor: `secondary.pink!important` }}
            onClick={() => {
              if (zoom > 1) setZoom(Math.round((zoom - 0.2) * 100) / 100);
            }}
          />
        </VStack>
        <Flex
          flexDir="column"
          justifyContent="space-between"
          fontFamily="heading"
          fontSize={{ base: `xs`, lg: `sm` }}
          color="primary.dark-blue"
          py="1.375rem"
          px="4"
          h="6.25rem"
          w={{ base: `60`, lg: `72` }}
          backgroundColor="white"
          boxShadow="lg"
          borderRadius="xs">
          <HStack gap="1rem">
            <Box
              w="1.375rem"
              h="1.375rem"
              borderRadius="lg"
              backgroundColor="primary.froneri-blue"
            />
            <Text mb="0">Froneri Offices</Text>
          </HStack>
          <HStack gap="1rem">
            <Box
              w="1.375rem"
              h="1.375rem"
              borderRadius="lg"
              backgroundColor="secondary.pale-blue"
            />
            <Text mb="0">Other Countries</Text>
          </HStack>
        </Flex>
      </Flex>
    </Box>
  );
};

export default InteractiveMap;
