import React from 'react';
import { Wrap, Box } from '@chakra-ui/react';
import ButtonCms from '@components/atoms/ButtonCms';

const CallToActions = ({
  ctas,
  primaryColor,
  primaryIconColor,
  secondaryColor,
  secondaryIconColor,
  secondaryIconBorderColor,
  hoverColor,
  dataLayerType,
  ...props
}) => {
  return (
    <Wrap
      direction={{ base: `column`, md: `row` }}
      align={{ md: `center` }}
      spacing="6"
      {...props}>
      {ctas.map((cta, index) => {
        // Set variant based on the ctas position.
        const variant = index % 2 === 0 ? `primary` : `secondary`;
        return (
          <Box key={cta.title} as="li">
            <ButtonCms
              data={cta}
              variant={variant}
              color={variant === `secondary` ? secondaryColor : primaryColor}
              iconColor={
                variant === `secondary` ? secondaryIconColor : primaryIconColor
              }
              iconBorderColor={
                variant === `secondary` ? secondaryIconBorderColor : null
              }
              hoverColor={hoverColor}
              dataLayerType={dataLayerType}
              width="100%"
            />
          </Box>
        );
      })}
    </Wrap>
  );
};

export default CallToActions;
