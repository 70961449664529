import React from 'react';
import { Flex } from '@chakra-ui/react';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import Card from '@components/molecules/Card';
import HeadlineAndCallToAction from '@components/molecules/HeadlineAndCallToAction';
import SliderArrows from '@components/molecules/SliderArrows';
import SliderPagination from '@components/molecules/SliderPagination';
import Section from '@components/atoms/Section';
import Wrapper from '@components/atoms/Wrapper';
import ButtonCms from '@components/atoms/ButtonCms';

const TeamCarousel = ({ data: { title, tagline, cta, teamMembers } }) => {
  return (
    <Section
      overflow="hidden"
      sx={{
        '.swiper': {
          py: 1, // to prevent overflow hidden cropping focus state on buttons.
          overflow: `visible`
        },
        '.swiper-wrapper': {
          width: `fit-content` // to ensure correct width is set on slider wrapper and dragging works.
        }
      }}>
      <Wrapper>
        <Swiper
          modules={[Navigation, Pagination]}
          navigation={{
            prevEl: `.prev`,
            nextEl: `.next`
          }}
          pagination={{
            el: `.pagination`,
            clickable: true,
            dynamicBullets: true,
            dynamicMainBullets: 5
          }}
          spaceBetween={25}
          slidesPerView={1.15}
          breakpoints={{
            600: {
              slidesPerView: 1.75,
              spaceBetween: 30
            },
            768: {
              slidesPerView: 2.1,
              spaceBetween: 30
            },
            1024: {
              slidesPerView: 2.5,
              spaceBetween: 30
            },
            1200: {
              slidesPerView: 3.1,
              spaceBetween: 30
            }
          }}>
          <HeadlineAndCallToAction
            title={title}
            tagline={tagline}
            cta={<SliderArrows />}
            slot="container-start"
            mb={{ base: 6, md: 10 }}
          />
          {teamMembers &&
            teamMembers.map((member) => (
              <SwiperSlide key={member.title}>
                <Card
                  data={{
                    strapiComponent: `elements.team-card`,
                    title: member.title,
                    tagline: member.tagline,
                    text: member.text,
                    quote: member.quote,
                    frontFace: member.thumbnail
                  }}
                />
              </SwiperSlide>
            ))}
          <Flex
            slot="container-end"
            align={{ md: `center` }}
            justify={{ md: `space-between` }}
            direction={{ base: `column`, md: `row-reverse` }}
            mt={{ base: 8, md: 16 }}>
            <SliderPagination color="primary.froneri-blue" />
            {cta && (
              <ButtonCms
                data={cta}
                mt={{ base: 8, md: 0 }}
                dataLayerType="team_card"
              />
            )}
          </Flex>
        </Swiper>
      </Wrapper>
    </Section>
  );
};

export default TeamCarousel;
