import React from 'react';
import { Box, SimpleGrid } from '@chakra-ui/react';
import Wrapper from '@components/atoms/Wrapper';
import Section from '@components/atoms/Section';
import ButtonCms from '@components/atoms/ButtonCms';
import Card from '@components/molecules/Card';
import HeadlineAndCallToAction from '@components/molecules/HeadlineAndCallToAction';
import getColorFromField from '@helpers/getColorFromField';

const TeamCardRow = ({
  data: { title, tagline, cta = null, cards = [], brandColor }
}) => {
  const bgColor = getColorFromField(brandColor) || `primary.light-grey`;

  return (
    <Section as="section" position="relative" bg={bgColor} mb={{ md: 10 }}>
      <Box position="relative" pb={{ base: 4, md: 14 }}>
        <Wrapper>
          <HeadlineAndCallToAction
            title={title}
            tagline={tagline}
            cta={cta && <ButtonCms data={cta} dataLayerType="section_title" />}
            mb={{ base: 6, md: 10 }}
          />
          <SimpleGrid columns={{ base: 1, md: 3 }} spacing="12" h="100%">
            {cards &&
              cards.length > 0 &&
              cards.map((cardData) => (
                <Card
                  key={`brand-cards-row-${cardData.id}`}
                  data={{ strapiComponent: `elements.brand-card`, ...cardData }}
                  alignButton="end"
                />
              ))}
          </SimpleGrid>
        </Wrapper>
      </Box>
    </Section>
  );
};

export default TeamCardRow;
