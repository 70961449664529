import React from 'react';
import { Icon } from '@chakra-ui/react';

const ChevronUpIcon = (props) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5096 10.9741L7.0545 16.5852C6.73132 16.9176 6.19412 16.9066 5.88486 16.5612L4.9986 15.5713C4.71793 15.2578 4.72809 14.7804 5.02186 14.4791L12.5096 6.80001L19.9794 14.4792C20.2726 14.7805 20.2824 15.2574 20.002 15.5706L19.116 16.5602C18.8065 16.906 18.2685 16.9166 17.9455 16.5833L12.5096 10.9741Z"
    />
  </Icon>
);

export default ChevronUpIcon;
