import React from 'react';
import { AspectRatio, Box, Flex, Heading, Text } from '@chakra-ui/react';
import Image from '@components/atoms/Image';
import Breadcrumbs from '@components/atoms/Breadcrumbs';
import { getTabIcon } from '@helpers/getIcon';

const Timeline = ({ data: { title, tagline, timeBlocks, breadcrumbs } }) => {
  return (
    <Box
      backgroundColor="primary.dark-blue"
      px={{ base: `5`, md: `8`, lg: `24`, xl: `44` }}
      py={{ base: `12`, md: `12`, lg: `24`, xl: `40` }}
      mb={{ base: `12`, md: `14` }}
      borderTopRadius={{ base: `sm`, md: `md` }}>
      <Box width={{ base: `100%`, md: `50%` }} mb={{ base: `8`, md: `24` }}>
        {breadcrumbs.crumbs && (
          <Breadcrumbs
            id="timeline-breadcrumbs"
            color="white"
            background="transparent"
            wrapperProps={{
              px: 0,
              pb: 4,
              sx: {
                a: { color: `white` },
                div: { color: `white` }
              }
            }}
            crumbs={breadcrumbs.crumbs}
            labelOverride={title}
          />
        )}
        <Heading variant="detail" size="xs" color="white">
          {tagline}
        </Heading>
        <Heading color="white" fontSize={{ base: `3xl`, md: `5xl` }}>
          {title}
        </Heading>
      </Box>
      {timeBlocks.map((block, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Box pos="relative" key={`${block.title}-${index}`}>
          <Flex
            justifyContent={{ base: null, md: `space-between` }}
            mb="24"
            flexDir={{
              base: `column`,
              md: index % 2 === 0 ? `row` : `row-reverse`
            }}>
            <AspectRatio
              ratio={1.5}
              mt={{ base: `16`, md: 0 }}
              mb={{ base: `8`, md: 0 }}
              width={{ base: `100%`, md: `40%` }}
              borderRadius="xs"
              overflow="hidden"
              zIndex={{ base: `1`, md: null }}
              {...(index % 2 === 0
                ? { borderBottomLeftRadius: `md` }
                : { borderBottomRightRadius: `md` })}>
              <Image image={block.image} />
            </AspectRatio>
            <Box width={{ base: `80%`, md: `40%` }}>
              <Heading color="white" size="xs" mb="2">
                {block.tagline}
              </Heading>
              <Heading
                color="primary.froneri-blue"
                fontSize={{ base: `60px`, md: `100px` }}
                fontWeight="800"
                mb="2">
                {block.title}
              </Heading>
              <Text color="white" fontSize="md">
                {block.text}
              </Text>
            </Box>
          </Flex>
          {index < timeBlocks.length - 1 && (
            <Box
              pos="absolute"
              top="0"
              bottom="-6rem"
              right={{ base: `calc(3rem - 1px)`, md: `calc(50% - 1px)` }}
              border="1px solid white"
            />
          )}
          <Flex
            pos="absolute"
            color="primary.dark-blue"
            background="white"
            top="0"
            right={{ base: `1.5rem`, md: `calc(50% - 1.5rem)` }}
            borderRadius="xs"
            w="12"
            h="12"
            justifyContent="center"
            alignItems="center"
            sx={{ '& > svg': { width: `6`, height: `6` } }}>
            {getTabIcon(block.icon.toLowerCase().replaceAll(` `, `-`))}
          </Flex>
        </Box>
      ))}
    </Box>
  );
};

export default Timeline;
