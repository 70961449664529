import React from 'react';
import { Box, Heading, Flex } from '@chakra-ui/react';
import { height as headerHeight } from '@components/organisms/Header';
import Wrapper from '@components/atoms/Wrapper';
import Image from '@components/atoms/Image';
import RoundEdge from '@components/atoms/RoundEdge';
import getColorFromField from '@helpers/getColorFromField';
import getTextColor from '@helpers/getTextColor';
import Breadcrumbs from '@components/atoms/Breadcrumbs';

const StandardHero = ({
  data: { title, tagline, image, color, breadcrumbs = [] },
  children
}) => {
  const bgColor = getColorFromField(color) || `primary.dark-blue`;
  const textColor = getTextColor(bgColor);
  return (
    <Box
      as="section"
      position="relative"
      bg={bgColor}
      pt={headerHeight}
      mb={{ md: 10 }}>
      <Flex
        position={{ md: `absolute` }}
        top="0"
        left="0"
        width="100%"
        height={{ base: image ? `56.25vw` : `100%`, md: `100%` }}
        mt={{ base: `-1.25rem`, md: `0` }}
        _after={{
          content: { md: `""` },
          position: `absolute`,
          top: 0,
          left: 0,
          display: `block`,
          width: `100%`,
          height: `100%`,
          bg: bgColor,
          opacity: 0.8
        }}
        sx={{
          '> .gatsby-image-wrapper': {
            flexGrow: 1
          }
        }}>
        {image && <Image image={image} loading="eager" />}
      </Flex>
      <Box
        position="relative"
        textAlign="center"
        pt={{ base: 14, md: 36 }}
        pb={{ base: 7, md: 36 }}>
        <Wrapper>
          {tagline && (
            <Heading
              as="p"
              variant="detail"
              size="sm"
              color={textColor}
              mb={{ base: 4, md: 8 }}>
              {tagline}
            </Heading>
          )}
          {title && (
            <Heading
              as="h1"
              size="5xl"
              color={textColor}
              fontWeight="900"
              mb="0">
              {title}
            </Heading>
          )}
        </Wrapper>
      </Box>
      <RoundEdge type="top" position="relative" />
      {breadcrumbs?.crumbs && (
        <Breadcrumbs
          id="standard-hero-breadcrumbs"
          crumbs={breadcrumbs.crumbs}
          labelOverride={title}
        />
      )}
      {children}
    </Box>
  );
};

export default StandardHero;
