import React from 'react';
import { Box, SimpleGrid } from '@chakra-ui/react';
import Wrapper from '@components/atoms/Wrapper';
import Section from '@components/atoms/Section';
import ButtonCms from '@components/atoms/ButtonCms';
import Card from '@components/molecules/Card';
import HeadlineAndCallToAction from '@components/molecules/HeadlineAndCallToAction';

const OtherBrandsRow = ({
  data: { title, tagline, cta = null, cards = [] }
}) => {
  return (
    <Section
      position="relative"
      backgroundColor="secondary.light-grey"
      pt="4.25rem"
      mb={{ md: 10 }}>
      <Box position="relative" pb={{ base: 4, md: 14 }}>
        <Wrapper>
          <HeadlineAndCallToAction
            title={title}
            tagline={tagline}
            cta={cta && <ButtonCms data={cta} dataLayerType="section_title" />}
            mb={{ base: 6, md: 10 }}
          />
          <SimpleGrid columns={{ base: 2, md: 4 }} spacing="12" h="100%">
            {cards &&
              cards.length > 0 &&
              cards.map((cardData) => (
                <Card
                  key={`other-brands-row-${cardData.id}`}
                  data={{ strapiComponent: `elements.brand-card`, ...cardData }}
                  alignButton="end"
                  hideCTA
                />
              ))}
          </SimpleGrid>
        </Wrapper>
      </Box>
    </Section>
  );
};

export default OtherBrandsRow;
