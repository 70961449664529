import React from 'react';
import { Icon } from '@chakra-ui/react';

const CheckIcon = (props) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.5607 6.12879C21.1464 6.71458 21.1464 7.66433 20.5607 8.25011L9 19.8108L3.43934 14.2501C2.85355 13.6643 2.85355 12.7146 3.43934 12.1288C4.02513 11.543 4.97487 11.543 5.56066 12.1288L9 15.5681L18.4393 6.12879C19.0251 5.54301 19.9749 5.54301 20.5607 6.12879Z"
      fill="white"
    />
  </Icon>
);

export default CheckIcon;
