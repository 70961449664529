import React, { useEffect } from 'react';
import { Heading } from '@chakra-ui/react';
import Button from '@components/atoms/Button';
import { ArrowRightIcon } from '@components/atoms/icons';

const FormStatus = ({
  responseText,
  sendingMessageText,
  sendAgainText,
  formDidError,
  submitInProgress,
  submitSuccess,
  onClick = () => {},
  buttonVariant = `primary`
}) => {
  useEffect(() => {
    if (typeof window !== `undefined`) {
      window.scrollTo({
        top: 0,
        behavior: `smooth`
      });
    }
  }, [submitInProgress, submitSuccess, formDidError]);

  return (
    <>
      {submitInProgress && (
        <Heading as="p" size="md" color="primary.dark-blue" mt="0" mb="0">
          {sendingMessageText}
        </Heading>
      )}
      {submitSuccess && (
        <>
          <Heading as="h2" size="2xl" color="primary.dark-blue" mt="0" mb="12">
            {responseText}
          </Heading>
          <Button
            variant={buttonVariant}
            size={buttonVariant === `solid` ? `lg` : undefined}
            rightIcon={buttonVariant === `primary` && <ArrowRightIcon />}
            onClick={onClick}>
            {sendAgainText}
          </Button>
        </>
      )}
      {formDidError && (
        <>
          <Heading as="p" size="md" color="red" mt="0" mb="12">
            Form errored.
          </Heading>
          <Button
            variant={buttonVariant}
            size={buttonVariant === `solid` ? `lg` : undefined}
            rightIcon={buttonVariant === `primary` && <ArrowRightIcon />}
            onClick={onClick}>
            {sendAgainText}
          </Button>
        </>
      )}
    </>
  );
};

export default FormStatus;
