import React from 'react';
import { Box } from '@chakra-ui/react';

const RoundEdge = ({ type = `top`, ...rest }) => {
  const radius = { base: `sm`, md: `md` };
  return (
    <Box
      as="span"
      display="block"
      width="100%"
      height={{ base: `2.75rem`, md: `3.625rem` }}
      background="white"
      borderTopLeftRadius={type === `top` ? radius : null}
      borderTopRightRadius={type === `top` ? radius : null}
      borderBottomLeftRadius={type === `bottom` ? radius : null}
      borderBottomRightRadius={type === `bottom` ? radius : null}
      {...rest}
    />
  );
};

export default RoundEdge;
