import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  Box,
  InputGroup,
  Input,
  InputRightElement,
  Hide
} from '@chakra-ui/react';
import SearchIcon from '@components/atoms/icons/SearchIcon';
import CloseIcon from '@components/atoms/icons/CloseIcon';

const Search = ({ compact = false, onToggle, ...rest }) => {
  const formRef = useRef(null);
  const [showSearch, setShowSearch] = useState(!compact);

  const handleDefaultSubmit = useCallback((formEvent) => {
    formEvent.preventDefault();
  }, []);

  const handleSubmitSearch = useCallback(() => {
    if (formRef.current !== null) {
      formRef.current.submit();
    }
  }, [formRef]);

  useEffect(() => {
    onToggle?.(!showSearch);
  }, [showSearch, onToggle]);

  const inputWidth = compact ? `45%` : `60`;

  return (
    <InputGroup
      as="form"
      ref={formRef}
      action="/search/"
      transition="width 0.1s ease-in"
      w={!showSearch && compact ? `12` : inputWidth}
      h={[`32px`, undefined, `40px`]}
      onSubmit={handleDefaultSubmit}
      {...rest}>
      {(showSearch || !compact) && (
        <Input
          name="query"
          border="1px solid transparent"
          outline={compact ? `1px solid` : `none`}
          outlineColor={compact ? `secondary.pink` : `none`}
          h={[`32px`, undefined, `40px`]}
          fontSize={[`sm`, `md`]}
          backgroundColor={compact ? `#fff` : `secondary.pale-blue`}
          p={[`0px 15px`, undefined, `10px 15px`]}
          marginTop={compact ? `1.5` : 0}
          borderRadius={compact ? `xs` : `sm`}
          placeholder="Search..."
          _focus={{ border: `1px solid transparent` }}
          _hover={{ border: `1px solid transparent` }}
        />
      )}
      <InputRightElement h={[`32px`, undefined, `40px`]}>
        {showSearch ? (
          <Box as="button" type="submit" onClick={handleSubmitSearch}>
            <SearchIcon
              sx={{
                width: `6`,
                height: `6`,
                marginRight: `5px`,
                marginTop: compact ? `3.5` : 0
              }}
            />
          </Box>
        ) : (
          <Box as="button" type="button" onClick={() => setShowSearch(true)}>
            <SearchIcon
              sx={{
                width: `6`,
                height: `6`,
                marginRight: `5px`,
                marginTop: compact ? `3.5` : 0
              }}
            />
          </Box>
        )}
        <Hide below="lg">
          <Hide above="xl">
            <Box
              as="button"
              onClick={() => setShowSearch(false)}
              display={showSearch ? `block` : `none`}>
              <CloseIcon
                sx={{
                  width: `6`,
                  height: `6`,
                  marginRight: `8 `,
                  marginTop: compact ? `3.5` : 0
                }}
              />
            </Box>
          </Hide>
        </Hide>
      </InputRightElement>
    </InputGroup>
  );
};

export default Search;
