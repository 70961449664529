import React from 'react';
import Button from '@components/atoms/Button';

const PaginationButton = ({
  value = ``,
  isActive = false,
  onClick = () => {}
}) => (
  <Button
    textColor={isActive ? `secondary.light-grey` : `primary.dark-blue`}
    color={isActive ? `secondary.pink` : `secondary.pale-blue`}
    px="0 !important"
    width={{ base: 12, md: 14 }}
    _hover={
      isActive
        ? {
            cursor: `default`
          }
        : {
            textColor: `secondary.pink`,
            bg: `secondary.light-grey`,
            boxShadow: `inset 0px 0px 0px 3px`
          }
    }
    onClick={onClick}>
    {value}
  </Button>
);

export default PaginationButton;
