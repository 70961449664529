import React from 'react';
import { Box, Heading, Text, Flex, VStack } from '@chakra-ui/react';
import Section from '@components/atoms/Section';
import Wrapper from '@components/atoms/Wrapper';
import Image from '@components/atoms/Image';
import ButtonCms from '@components/atoms/ButtonCms';
import getColorFromField from '@helpers/getColorFromField';
import getTextColor from '@helpers/getTextColor';

const HalfAndHalf = ({ data: { blocks, brandColor } }) => {
  return (
    <Section borderTopRadius={{ base: `sm`, lg: `md` }} overflow="hidden">
      {blocks && blocks.length > 0 && (
        <>
          {blocks.map(
            ({
              title,
              tagline,
              text,
              ctaType,
              ctaLink,
              ctaTitle,
              image,
              video,
              color,
              alignment
            }) => {
              const isImageRight = alignment === `Image Right`;
              const bgColor =
                getColorFromField(brandColor || color) ||
                `primary.froneri-blue`;
              const textColor = getTextColor(bgColor);

              return (
                <Box position="relative" bg={bgColor} key={title}>
                  <Wrapper>
                    <Flex
                      position="relative"
                      direction="column"
                      align="left"
                      justify="flex-end"
                      width={{ lg: `50%` }}
                      height={{ lg: `56.25rem` }}
                      ml={!isImageRight ? { lg: `auto` } : null}>
                      <VStack
                        spacing={{ base: 4, md: 6 }}
                        align="start"
                        maxWidth={{ lg: `36rem` }}
                        pl={!isImageRight ? { lg: 14 } : null}
                        pr={isImageRight ? { lg: 14 } : null}
                        pt={{ base: 16, lg: 14 }}
                        pb={{ base: 10, lg: 14 }}>
                        {tagline && (
                          <Heading
                            as="p"
                            variant="detail"
                            size="xs"
                            position={{ lg: `absolute` }}
                            top={{ lg: 14 }}
                            left={{ lg: isImageRight ? 0 : 14 }}
                            color={textColor}
                            mb="0">
                            {tagline}
                          </Heading>
                        )}
                        {title && (
                          <Heading as="h2" size="3xl" color={textColor} mb="0">
                            {title}
                          </Heading>
                        )}
                        {text && (
                          <Text color={textColor} mb="0">
                            {text}
                          </Text>
                        )}
                        {ctaLink && (
                          <ButtonCms
                            data={{
                              type: ctaType,
                              link: ctaLink,
                              title: ctaTitle
                            }}
                            variant="secondary"
                            color={textColor}
                            iconBorderColor="white"
                            dataLayerType="half_block"
                          />
                        )}
                      </VStack>
                    </Flex>
                  </Wrapper>
                  <Flex
                    position={{ lg: `absolute` }}
                    width={{ base: `100%`, lg: `50%` }}
                    height={{ base: `100vw`, lg: `100%` }}
                    top={{ lg: `0` }}
                    left={{ lg: !isImageRight ? 0 : null }}
                    right={{ lg: isImageRight ? 0 : null }}
                    borderTopLeftRadius={{ lg: isImageRight ? `sm` : `md` }}
                    borderTopRightRadius={{ lg: !isImageRight ? `sm` : `md` }}
                    borderBottomLeftRadius={{ lg: isImageRight ? `xl` : null }}
                    borderBottomRightRadius={{
                      lg: !isImageRight ? `xl` : null
                    }}
                    overflow="hidden"
                    sx={{
                      '> .gatsby-image-wrapper': {
                        flexGrow: 1
                      }
                    }}>
                    {video && (
                      <Box
                        as="video"
                        src={video.url}
                        controls={false}
                        autoPlay
                        loop
                        muted
                        playsInline
                        objectFit="cover"
                        width="100%"
                        height="100%"
                      />
                    )}
                    {!video && image && <Image image={image} />}
                  </Flex>
                </Box>
              );
            }
          )}
        </>
      )}
    </Section>
  );
};

export default HalfAndHalf;
