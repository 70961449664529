import React from 'react';
import { Icon } from '@chakra-ui/react';

const IceCream4Icon = (props) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.4244 2.28519C8.17275 2.58749 5.67151 5.03613 5.67151 7.90799V16.4933C5.67151 17.5212 6.63622 18.3373 7.85114 18.3373L11.0785 18.3373V21.1505C11.0785 21.6196 11.4589 22 11.928 22C12.3972 22 12.7776 21.6196 12.7776 21.1505V18.3373L16.3554 18.3372C17.5703 18.3372 18.5708 17.5212 18.5708 16.4934L18.5708 7.81734H18.5693C17.7834 7.81708 16.8192 7.60525 15.7479 6.72909C14.926 6.06407 14.4616 5.18738 14.4973 4.31068C14.4973 4.10751 14.5252 3.90436 14.5766 3.70496C14.6553 3.3999 14.789 3.10359 14.9619 2.82938C14.4194 2.59071 13.8638 2.43009 13.287 2.34075C12.9139 2.28296 12.532 2.25498 12.139 2.25498C11.8889 2.25498 11.6744 2.25498 11.4244 2.28519ZM12.8101 3.99169C12.5934 3.96713 12.3701 3.95498 12.139 3.95498C11.88 3.95498 11.7627 3.95668 11.6283 3.97292L11.605 3.97573L11.5817 3.97789C9.02312 4.21577 7.37151 6.08484 7.37151 7.90799V16.4712C7.3772 16.4783 7.38714 16.4889 7.40373 16.5029C7.47061 16.5595 7.61979 16.6373 7.85112 16.6373L16.3554 16.6372C16.5894 16.6372 16.7546 16.5574 16.8352 16.4908C16.8525 16.4764 16.8637 16.4651 16.8708 16.4571L16.8708 9.26099C16.1686 9.04236 15.4284 8.66327 14.675 8.04773C13.5598 7.14396 12.7479 5.80526 12.7974 4.27583C12.7983 4.18018 12.8026 4.08543 12.8101 3.99169ZM11.2617 13.534H13.4819C13.9341 13.534 14.3452 13.8639 14.3452 14.267C14.3452 14.6701 13.9752 15 13.5229 15H10.4395C9.98724 15 9.61719 14.6701 9.61719 14.267V8.73298C9.61719 8.32987 9.98724 8 10.4395 8C10.8917 8 11.2617 8.32987 11.2617 8.73298V13.534Z"
    />
    <path d="M-19.12 -632.853L-325.813 261.293C-331.287 277.231 -323.204 295.053 -307.272 301.231C-235.704 328.815 -160.099 346.397 -81.8987 354.481V591.268C-81.8987 715.361 103.781 715.361 103.781 591.268L103.787 354.481C181.052 346.643 256.187 329.284 326.8 302.179C344.16 296.705 353.664 278.403 347.727 261.283L41.0332 -632.851C31.2728 -661.381 -9.38271 -661.621 -19.128 -632.851L-19.12 -632.853ZM39.844 591.267C39.844 631.444 -17.9319 631.444 -17.9319 591.267V358.76H39.844V591.267ZM277.351 252.48C109.737 309.777 -87.8226 309.777 -255.436 252.48L-237.837 201.36C-78.3173 247.245 99.9894 247.245 259.763 201.36L277.351 252.48ZM138.044 -153.587L239.085 140.973C210.075 149.291 179.888 155.947 149.216 161.181C166.576 140.493 152.065 108.64 124.737 108.64C93.3627 108.64 80.992 149.296 106.675 166.88C18.4667 176.625 -74.472 172.818 -160.312 154.995C-165.546 127.891 -200.249 120.052 -217.129 140.964L10.8707 -524.236L119.048 -209.223C97.8708 -190.431 109.756 -155.25 138.048 -153.587L138.044 -153.587Z" />
  </Icon>
);

export default IceCream4Icon;
