import React from 'react';
import { Flex } from '@chakra-ui/react';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import SpotlightOnCard from '@components/molecules/SpotlightOnCard';
import HeadlineAndCallToAction from '@components/molecules/HeadlineAndCallToAction';
import SliderArrows from '@components/molecules/SliderArrows';
import SliderPagination from '@components/molecules/SliderPagination';
import Section from '@components/atoms/Section';
import Wrapper from '@components/atoms/Wrapper';
import ButtonCms from '@components/atoms/ButtonCms';
import getColorFromField from '@helpers/getColorFromField';

const SpotlightOnCarousel = ({ data: { title, tagline, cta, cards } }) => {
  return (
    <Section
      overflow="hidden"
      sx={{
        '.swiper': {
          py: 1, // to prevent overflow hidden cropping focus state on buttons.
          overflow: `visible`
        },
        '.swiper-wrapper': {
          width: `fit-content` // to ensure correct width is set on slider wrapper and dragging works.
        }
      }}>
      <Wrapper>
        <Swiper
          modules={[Navigation, Pagination]}
          navigation={{
            prevEl: `.prev`,
            nextEl: `.next`
          }}
          pagination={{
            el: `.pagination`,
            clickable: true,
            dynamicBullets: true,
            dynamicMainBullets: 5
          }}
          spaceBetween={30}
          slidesPerView={1.15}
          breakpoints={{
            768: {
              slidesPerView: 1.1,
              spaceBetween: 60
            },
            1024: {
              slidesPerView: 1.25
            },
            1200: {
              slidesPerView: 1.6,
              spaceBetween: 80
            }
          }}>
          <HeadlineAndCallToAction
            title={title}
            tagline={tagline}
            cta={<SliderArrows />}
            slot="container-start"
            mb={{ base: 6, md: 10 }}
          />
          {cards &&
            cards.map((card) => (
              <SwiperSlide key={card.title}>
                <SpotlightOnCard
                  title={card.title}
                  tagline={card.tagline}
                  text={card.text}
                  cta={{
                    type: card.ctaType,
                    link: card.ctaLink,
                    title: card.ctaTitle
                  }}
                  image={card.image}
                  color={getColorFromField(card.color)}
                />
              </SwiperSlide>
            ))}
          <Flex
            slot="container-end"
            align={{ md: `center` }}
            justify={{ md: `space-between` }}
            direction={{ base: `column`, md: `row-reverse` }}
            mt={{ base: 8, md: 16 }}>
            <SliderPagination color="primary.froneri-blue" />
            {cta && (
              <ButtonCms
                data={cta}
                mt={{ base: 8, md: 0 }}
                dataLayerType="spotlight"
              />
            )}
          </Flex>
        </Swiper>
      </Wrapper>
    </Section>
  );
};

export default SpotlightOnCarousel;
