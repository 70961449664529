import React from 'react';
import { Icon } from '@chakra-ui/react';

const IceCream1Icon = (props) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.27184 7.09836C7.66564 5.15854 9.38066 3.69853 11.4367 3.69853C13.4927 3.69853 15.2078 5.15854 15.6016 7.09836H7.27184ZM5.54927 7.09836C5.96172 4.2162 8.44048 2.0007 11.4367 2.0007C14.4329 2.0007 16.9117 4.2162 17.3241 7.09836H17.8229H18.2355C18.7043 7.09836 19.0844 7.47843 19.0844 7.94727C19.0844 8.41611 18.7043 8.79618 18.2355 8.79618H17.0953L11.4367 21.9995L5.77812 8.79618H4.63868C4.16984 8.79618 3.78976 8.41611 3.78976 7.94727C3.78976 7.47843 4.16984 7.09836 4.63868 7.09836H5.05048H5.54927ZM11.4367 17.6894L7.62529 8.79618H15.2481L11.4367 17.6894ZM12.8501 6.24904C13.3196 6.24904 13.7002 5.86844 13.7002 5.39894C13.7002 4.92943 13.3196 4.54883 12.8501 4.54883C12.3806 4.54883 12 4.92943 12 5.39894C12 5.86844 12.3806 6.24904 12.8501 6.24904Z"
    />
  </Icon>
);

export default IceCream1Icon;
