import React from 'react';

const SiteLogo = ({ title, ...props }) => {
  return (
    <svg
      width="181"
      height="51"
      viewBox="0 0 181 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="siteLogoTitle"
      {...props}>
      <title id="siteLogoTitle">{title}</title>
      <g clipPath="url(#clip0_2479_3084)">
        <path
          d="M33.709 9.67363C32.8697 9.65265 32.4416 10.0471 32.5129 10.8529C32.6388 12.2421 32.5591 13.6271 32.5675 15.0162C32.5759 16.951 32.8906 17.1902 34.7708 16.611C36.7224 16.0067 37.8849 13.9712 37.3309 12.133C36.907 10.7354 35.4046 9.7156 33.7132 9.67363M32.4626 25.2145C32.4626 26.6247 32.4752 28.0348 32.4626 29.4449C32.4374 31.8329 31.2203 33.1382 28.8407 33.2557C27.5732 33.3186 26.2932 33.3018 25.0258 33.2515C23.3093 33.1843 22.0082 31.7994 21.9747 30.0493C21.9537 28.87 21.9705 27.6907 21.9705 26.5156C21.9705 19.1165 21.9705 11.7133 21.9663 4.31427C21.9663 3.25666 22.1929 2.32496 23.0113 1.56953C23.6114 1.01555 24.3207 0.830888 25.0845 0.818297C29.0128 0.738557 32.9536 0.423794 36.8567 1.15404C39.2405 1.59891 41.4984 2.35014 43.4121 3.95334C45.8043 5.95943 47.1767 8.50271 47.5964 11.5706C48.1336 15.5114 46.9207 18.9067 44.3522 21.878C43.4877 22.8811 43.4793 23.0951 44.092 24.2744C45.8211 27.6151 47.7894 30.8005 50.4502 33.4865C54.2232 37.2972 58.8229 39.4208 64.1361 40.042C70.2929 40.7596 75.7152 38.913 80.6297 35.3037C82.4385 33.9775 83.7563 34.0237 85.4812 35.4548C86.4045 36.2186 87.3698 36.9363 88.1714 37.847C89.6697 39.5551 89.5899 41.8382 87.8231 43.3071C84.9986 45.6615 81.851 47.4998 78.4096 48.8176C73.8476 50.5676 69.1136 51.2307 64.2578 50.9328C58.6928 50.5886 53.5685 48.8553 48.847 45.8714C43.3492 42.3964 39.3076 37.6036 36.1138 32.0218C34.838 29.7933 33.7006 27.4976 32.471 25.2187"
          fill="#00BAD5"
        />
        <path
          d="M73.5661 16.9971C73.8347 13.4634 70.557 10.2654 66.6413 10.2276C63.3006 10.1941 60.0187 13.4382 60.0061 16.7663C59.9893 20.5561 63.0866 23.6995 66.7588 23.8128C69.8015 23.9051 73.885 20.9422 73.5661 17.0013M66.8511 -5.95578e-05C75.8785 -0.109177 84.0708 7.04224 84.033 17.1818C83.9952 26.759 76.571 34.1245 66.977 34.2252C57.5258 34.3217 49.7826 27.1199 49.7112 17.2238C49.6315 6.92892 58.1679 -0.180524 66.8511 -5.95578e-05Z"
          fill="#00BAD5"
        />
        <path
          d="M104.254 12.5444C104.254 10.0977 104.254 7.8146 104.254 5.53152C104.254 4.78029 104.216 4.02485 104.434 3.28621C104.887 1.75856 105.979 0.902406 107.888 0.76391C109.105 0.675777 110.335 0.646399 111.552 0.852044C113.025 1.09966 114.087 2.11949 114.301 3.58838C114.393 4.21371 114.439 4.85583 114.439 5.48955C114.448 13.3125 114.448 21.1354 114.439 28.9583C114.439 29.5207 114.406 30.0914 114.309 30.6454C114.112 31.8205 113.411 32.6305 112.291 32.9747C108.555 34.1288 105.206 33.1929 103.133 29.7011C101.492 26.9354 99.4609 24.4551 97.5681 21.874C97.4758 21.7481 97.3877 21.5928 97.1778 21.5886C96.9932 21.8069 97.0771 22.0755 97.0771 22.3231C97.0687 24.3963 97.0687 26.4696 97.0771 28.5428C97.0771 29.1346 97.0099 29.7179 96.8882 30.2929C96.4811 32.2276 95.4697 33.1635 93.493 33.2642C92.1584 33.3314 90.8112 33.3692 89.4724 33.1593C88.0833 32.9453 87.2145 32.1773 86.8536 30.8301C86.6773 30.167 86.5598 29.4997 86.5598 28.8072C86.564 20.8416 86.5598 12.8802 86.5682 4.91458C86.5682 2.60632 87.6972 1.29691 89.9551 0.801682C93.1698 0.0966127 95.7089 1.07867 97.5471 3.81501C99.3854 6.55555 101.324 9.22054 103.423 11.7638C103.628 12.0156 103.821 12.2758 104.241 12.5402"
          fill="#00BAD5"
        />
        <path
          d="M150.801 14.5966C150.801 14.9953 150.793 15.3982 150.801 15.7969C150.822 16.5607 151.158 16.8545 151.918 16.7915C152.127 16.7747 152.337 16.7369 152.547 16.7118C154.541 16.4851 155.812 14.9491 155.741 12.8423C155.682 11.0796 154.184 9.65688 152.295 9.56455C151.166 9.50999 150.583 9.93387 150.692 10.8068C150.852 12.0701 150.675 13.3375 150.797 14.5966M140.305 17.0769C140.305 12.9052 140.313 8.73357 140.297 4.56191C140.292 3.40358 140.75 2.4593 141.488 1.62412C142 1.04916 142.664 0.738591 143.427 0.734394C146.088 0.717607 148.749 0.449009 151.41 0.604292C154.855 0.801544 158.2 1.40169 161.138 3.39099C165.692 6.47567 167.505 12.6996 165.348 17.761C164.739 19.1879 163.929 20.4637 162.859 21.5885C161.956 22.5328 161.952 22.8685 162.628 24.0101C163.501 25.4916 164.357 26.9814 165.217 28.4713C165.368 28.7357 165.511 29.0085 165.637 29.2897C166.544 31.3461 165.587 33.0878 163.354 33.2221C161.335 33.3438 159.3 33.3145 157.277 33.2389C156.026 33.1928 155.074 32.5087 154.436 31.3965C153.475 29.722 152.488 28.06 151.502 26.3981C151.364 26.1672 151.255 25.7308 150.986 25.8063C150.642 25.8986 150.814 26.3351 150.81 26.6163C150.789 27.5102 150.805 28.4084 150.801 29.3023C150.793 31.7784 149.836 32.9451 147.393 33.2053C145.95 33.3606 144.464 33.4655 143.033 33.1004C141.111 32.6052 140.305 31.5056 140.301 29.5121C140.288 25.3656 140.301 21.215 140.301 17.0685"
          fill="#00BAD5"
        />
        <path
          d="M117.856 17.1861C117.856 13.1109 117.856 9.03581 117.856 4.96068C117.856 4.18006 117.927 3.41204 118.217 2.6734C118.716 1.41015 119.677 0.805804 120.991 0.721867C124.991 0.46586 128.999 0.591765 132.998 0.629537C135.852 0.654718 137.086 2.0103 137.103 4.87254C137.103 5.34259 137.124 5.82103 137.057 6.28268C136.788 8.07893 135.504 9.28342 133.649 9.38415C132.453 9.4471 131.248 9.46389 130.052 9.32119C129.146 9.21208 128.613 9.58979 128.407 10.4711C128.264 11.0881 128.331 11.6924 128.508 12.2842C128.68 12.8717 129.183 12.9976 129.695 12.9431C130.732 12.8381 131.769 12.9137 132.801 12.8801C136.238 12.7668 137.405 15.201 136.939 17.9793C136.641 19.7629 135.432 20.7408 133.619 20.8583C132.255 20.9464 130.896 21.0094 129.527 20.8835C128.696 20.8079 128.352 21.299 128.357 22.1509C128.348 22.6923 128.394 23.2211 128.369 23.7667C128.378 24.4676 128.529 24.7698 129.263 24.7866C130.887 24.8243 132.511 24.732 134.136 24.8747C135.978 25.0384 137.141 26.0708 137.346 27.9216C137.472 29.0296 137.413 30.1375 137.065 31.2077C136.742 32.194 136.108 32.8697 135.051 33.0711C134.471 33.1802 133.888 33.2516 133.296 33.2516C129.951 33.2516 126.606 33.26 123.262 33.2516C122.393 33.2516 121.52 33.2054 120.651 33.1257C119.01 32.9746 117.852 31.5309 117.843 29.6213C117.831 26.3688 117.848 23.1204 117.852 19.8679C117.852 18.9739 117.852 18.0758 117.852 17.1819"
          fill="#00BAD5"
        />
        <path
          d="M0.0211904 17.0939C0.0211904 12.9935 0.0799462 8.88903 0.000206238 4.79291C-0.045959 2.26222 1.61599 0.713586 3.82772 0.680011C7.83151 0.612862 11.8395 0.499547 15.8349 0.726176C17.9249 0.843688 18.7559 1.72502 19.0832 3.78987C19.2343 4.74675 19.2385 5.71622 19.0203 6.6689C18.6216 8.41899 17.4549 9.3297 15.5621 9.39265C14.2904 9.43462 13.0188 9.30452 11.7514 9.49338C11.1428 9.58571 10.6098 9.79555 10.4629 10.4209C10.2867 11.1805 10.2447 11.9779 10.7021 12.6746C10.891 12.96 11.2519 12.8928 11.5541 12.9054C13.1069 12.9558 14.6682 12.7753 16.2126 13.0061C17.8116 13.2454 18.7349 14.1603 19.0287 15.7467C19.0832 16.0488 19.1378 16.351 19.1546 16.6574C19.3141 19.6623 17.5178 21.3285 14.5423 20.9256C13.7449 20.8165 12.9433 20.8081 12.1459 20.8458C10.87 20.9088 10.1859 21.6726 10.3286 22.9443C10.5469 24.9168 10.5007 26.8935 10.5469 28.8702C10.6014 31.1197 9.91314 33.0754 6.73613 33.2265C5.38895 33.2895 4.04596 33.2895 2.71976 33.0796C0.973873 32.8068 0.0463715 31.6653 0.0379778 29.8187C0.0211904 26.52 0.033781 23.2212 0.0295841 19.9183C0.0295841 18.974 0.0295841 18.0339 0.0295841 17.0897"
          fill="#00BAD5"
        />
        <path
          d="M168.755 17.0392C168.755 13.1277 168.755 9.21626 168.755 5.30481C168.755 2.19495 169.821 0.969469 172.91 0.646313C174.219 0.507817 175.55 0.528801 176.855 0.75543C178.819 1.09537 179.721 2.20754 179.965 4.3815C180.158 6.1022 179.986 7.81452 179.973 9.53102C179.939 14.6428 179.96 19.7587 179.96 24.8705C179.96 26.4023 179.96 27.9342 179.96 29.466C179.952 31.8288 178.983 32.983 176.658 33.218C175.159 33.3691 173.648 33.3103 172.142 33.2138C169.787 33.0627 168.767 31.5728 168.755 29.2688C168.755 29.2226 168.755 29.1764 168.755 29.1261C168.755 25.0971 168.755 21.0681 168.755 17.0392Z"
          fill="#00BAD5"
        />
      </g>
      <defs>
        <clipPath id="clip0_2479_3084">
          <rect width="180.053" height="51" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SiteLogo;
