const linkResolver = (slug, contentType) => {
  if (contentType === `article`) {
    return `/articles/${slug}`;
  }

  if (contentType === `project`) {
    return `/projects/${slug}`;
  }

  if (contentType === `brand`) {
    return `/brands/${slug}`;
  }

  if (contentType === `landing-page`) {
    return slug === `home` ? `/` : `/${slug}`;
  }

  return ``;
};

export default linkResolver;
