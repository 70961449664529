import {
  Box,
  Flex,
  Heading,
  Tabs as ChakraTabs,
  TabList,
  Tab,
  Text,
  TabPanels,
  TabPanel,
  AspectRatio
} from '@chakra-ui/react';
import React from 'react';
import ButtonCms from '@components/atoms/ButtonCms';
import Wrapper from '@components/atoms/Wrapper';
import Image from '../atoms/Image';
import getColorFromField from '../../helpers/getColorFromField';
import { getTabIcon } from '../../helpers/getIcon';
import { stringToId } from '../../helpers/stringFormatters';

const Tabs = ({ data: { title, tagline, cta, tabList } }) => {
  const tabTitles = [];
  const tabContent = tabList.map((tab) => {
    tabTitles.push({ title: tab.title, icon: tab.icon });
    return { ...tab };
  });

  return (
    <Wrapper>
      <Flex w="100%" justifyContent="space-between" alignItems="flex-end">
        <Box>
          <Heading variant="detail" size={[`xs`]} color="secondary.mid-grey">
            {tagline}
          </Heading>
          <Heading fontSize={[`2xl`, `3xl`, undefined, `5xl`]} mb={{ base: 0 }}>
            {title}
          </Heading>
        </Box>
        {cta && <ButtonCms data={{ ...cta }} />}
      </Flex>
      <ChakraTabs
        id="tabs-chakra-tabs"
        variant="soft-rounded"
        display="flex"
        minH="96"
        flexDir="row"
        flexWrap={{ base: `wrap`, lg: `unset` }}
        justifyContent="space-between"
        mt="12">
        <TabList
          id="tabs-tab-list"
          flexDir={{ base: `row`, lg: `column` }}
          w={{ base: `100%`, lg: `33%` }}
          pb={{ base: `4` }}
          mb="2"
          overflowX={{ base: `scroll`, md: `auto` }}>
          {tabTitles.map((tabTitle, i) => (
            <Tab
              // eslint-disable-next-line react/no-array-index-key
              key={`${stringToId(tabTitle.title)}-${i}`}
              fontFamily="Roc Grotesk"
              borderRadius="xs"
              color="secondary.mid-blue"
              justifyContent="flex-start"
              px="4"
              w="232px"
              h="12"
              flexShrink="0"
              backgroundColor={{
                base: `secondary.light-grey`,
                lg: `transparent`
              }}
              mr={{ base: `2`, lg: null }}
              mt={{ base: null, lg: `2` }}
              _hover={{
                bgColor: `secondary.light-grey`,
                color: `secondary.pink`
              }}
              _selected={{
                bgColor: `secondary.pale-blue`,
                color: `primary.dark-blue`
              }}>
              <Box mr="1">{getTabIcon(tabTitle.icon)}</Box>
              <Text mt="1" mb="1" lineHeight="1rem">
                {tabTitle.title}
              </Text>
            </Tab>
          ))}
        </TabList>
        <TabPanels w="66%" pb="28">
          {tabContent.map((tab, i) => (
            <TabPanel
              // eslint-disable-next-line react/no-array-index-key
              key={`${stringToId(tab.title)}-${i}`}
              ml={{ base: `0`, md: `4` }}
              p="0">
              <Flex
                justifyContent="space-between"
                flexWrap={{ base: `wrap`, lg: `unset` }}>
                <Box w="50%" maxW="422px" minW="72" mb="8">
                  <Box
                    position="relative"
                    mr="calc(16%)"
                    _before={{
                      content: `""`,
                      display: `block`,
                      position: `absolute`,
                      top: `-6%`,
                      width: `43%`,
                      height: `55%`,
                      bg: getColorFromField(tab.cardColor),
                      borderRadius: `xs`
                    }}>
                    <AspectRatio
                      mt="6%"
                      ml="16%"
                      width="100%"
                      ratio={1}
                      borderRadius="xs"
                      borderBottomLeftRadius="md"
                      overflow="hidden">
                      <Image image={tab.cardImage} />
                    </AspectRatio>
                  </Box>
                </Box>
                <Box
                  w={{ base: `100%`, lg: `50%` }}
                  maxW={{ base: `72`, lg: `422px` }}
                  ml={{ base: `0`, sm: `4` }}>
                  <Heading
                    variant="detail"
                    size={[`xs`]}
                    color="secondary.mid-grey">
                    {tab.detailTagline}
                  </Heading>
                  <Heading fontSize={{ base: `2xl`, md: `3xl` }}>
                    {tab.detailTitle}
                  </Heading>
                  <Text>{tab.detailText}</Text>
                  {tab.detailCta ? (
                    <ButtonCms
                      data={{ ...tab.detailCta }}
                      variant="secondary"
                    />
                  ) : (
                    <Box h="9" />
                  )}
                </Box>
              </Flex>
            </TabPanel>
          ))}
        </TabPanels>
      </ChakraTabs>
    </Wrapper>
  );
};

export default Tabs;
