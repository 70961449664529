import React, { useCallback } from 'react';
import Link from '@components/atoms/Link';
import { Link as GatsbyLink } from 'gatsby';
import dataLayerPush from '@helpers/dataLayerPush';

const ConditionalLink = ({ cta, children }) => {
  const captureClick = useCallback(() => {
    dataLayerPush({
      event: `cta_click`,
      interaction: {
        click_text: cta.title,
        link_url: cta.link,
        cta_type: `mondelez-card`
      }
    });
  }, [cta]);

  return cta !== null && cta.type === `internal` ? (
    <GatsbyLink to={cta.link || ``} onClick={captureClick}>
      {children}
    </GatsbyLink>
  ) : (
    <Link href={cta.link || ``} target="_blank" onClick={captureClick}>
      {children}
    </Link>
  );
};

export default ConditionalLink;
