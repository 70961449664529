import { Heading, VStack, Box } from '@chakra-ui/react';
import { QuoteIcon } from '@components/atoms/icons';
import React from 'react';

const iconDimensions = {
  x: [`6`, `6`, `6`, `10`],
  y: [`6`, `6`, `6`, `7`],
  w: [`4`, `8`, `10`, `10`],
  h: [`4`, `8`, `10`, `10`]
};

const Quote = ({ quote }) => {
  return (
    <VStack
      pos="relative"
      backgroundColor="primary.froneri-blue"
      p={[`6`, `6`, `6`, `10`]}
      pointerEvents="none">
      <Box pos="absolute" top={iconDimensions.y} left={iconDimensions.x}>
        <QuoteIcon mb="6" mr="auto" h={iconDimensions.h} w={iconDimensions.w} />
      </Box>
      <Heading fontSize={[`1.6rem`, `3xl`, `3xl`, `3xl`, `4xl`]}>
        {quote}
      </Heading>
      <Box pos="absolute" bottom={iconDimensions.y} right={iconDimensions.x}>
        <QuoteIcon
          mt="6!important"
          ml="auto!important"
          h={iconDimensions.h}
          w={iconDimensions.w}
          transform="rotate(180deg)"
        />
      </Box>
    </VStack>
  );
};

export default Quote;
