import React from 'react';
import { Icon } from '@chakra-ui/react';

const SearchIcon = (props) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7778 6C9.13908 6 7 8.13908 7 10.7778C7 13.4165 9.13908 15.5556 11.7778 15.5556C14.4165 15.5556 16.5556 13.4165 16.5556 10.7778C16.5556 8.13908 14.4165 6 11.7778 6ZM5 10.7778C5 7.03451 8.03451 4 11.7778 4C15.521 4 18.5556 7.03451 18.5556 10.7778C18.5556 12.2881 18.0615 13.6831 17.2263 14.8099L19.7074 17.2932C20.0978 17.6839 20.0975 18.3171 19.7068 18.7074C19.3161 19.0978 18.6829 19.0975 18.2926 18.7068L15.8124 16.2244C14.6852 17.0608 13.2893 17.5556 11.7778 17.5556C8.03451 17.5556 5 14.521 5 10.7778Z"
    />
  </Icon>
);

export default SearchIcon;
