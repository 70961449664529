import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Flex,
  Text
} from '@chakra-ui/react';
import React, { useState } from 'react';
import AddIcon from '@components/atoms/icons/AddIcon';
import MinusIcon from '@components/atoms/icons/MinusIcon';
import dataLayerPush from '@helpers/dataLayerPush';
import { stringToId } from '@helpers/stringFormatters';

const AccordionItemContent = ({ isExpanded, title, text, index }) => {
  return (
    <>
      <AccordionButton
        id={`${stringToId(title)}-${index}-accordion-button`}
        px="0"
        textAlign="start"
        _hover={{ bgColor: `unset` }}>
        <Flex>
          <Text
            fontWeight="bold"
            fontSize={[`sm`, `md`]}
            mt="2"
            mb={isExpanded ? `0` : `4`}
            transition="margin-bottom 0.1s">
            {title}
          </Text>
        </Flex>
      </AccordionButton>
      <AccordionPanel
        id={`${stringToId(title)}-${index}-accordion-panel`}
        px="0"
        fontSize={[`sm`, `md`]}>
        {text}
      </AccordionPanel>
    </>
  );
};

const FAQItems = ({ items, dark = false }) => {
  const iconColor = dark ? `primary.dark-blue` : `white`;
  const iconBackground = dark ? `white` : `primary.dark-blue`;

  const [accordionIndex, setAccordionIndex] = useState(-1);
  const handleClick = (index, item) => {
    setAccordionIndex(accordionIndex === index ? -1 : index);
    // TODO Flesh out the datalayer push
    dataLayerPush({
      event: `accordion_click`,
      interaction: {
        accordion_title: item.title
      }
    });
  };

  return (
    <Accordion allowToggle index={accordionIndex} id="faq-items-accordion">
      {items.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Flex key={`${stringToId(item.title)}-${index}-accordion-item-wrapper`}>
          <AccordionItem
            id={`${stringToId(item.title)}-${index}-accordion-item`}
            mr="4"
            minH="24"
            w="100%"
            borderColor={dark ? `white` : `primary.froneri-blue`}
            onClick={() => handleClick(index, item)}
            border={index === 0 ? `none` : null}>
            <AccordionItemContent index={index} {...item} />
          </AccordionItem>
          <Flex
            alignItems="center"
            justifyContent="center"
            borderRadius="50%"
            mt="6"
            w="12"
            h="12"
            minW="12"
            cursor="pointer"
            backgroundColor={iconBackground}
            onClick={() => handleClick(index, item)}>
            {accordionIndex === index ? (
              <MinusIcon color={iconColor} w="6" h="6" />
            ) : (
              <AddIcon color={iconColor} w="6" h="6" />
            )}
          </Flex>
        </Flex>
      ))}
    </Accordion>
  );
};

export default FAQItems;
