import React from 'react';
import {
  Box,
  Text,
  Heading,
  VStack,
  AspectRatio,
  Stack
} from '@chakra-ui/react';
import Image from '@components/atoms/Image';
import ButtonCms from '@components/atoms/ButtonCms';

const SpotlightOnCard = ({
  title,
  tagline,
  text,
  cta,
  image,
  color,
  ...props
}) => {
  return (
    <Stack
      as="article"
      direction={{ base: `column`, md: `row` }}
      spacing={{ base: 8, md: 10, xl: 14 }}
      alignItems="center"
      textAlign="left"
      {...props}>
      <Box width={{ base: `100%`, md: `55%` }}>
        <Box
          position="relative"
          pl={{ base: `8%`, md: `14%` }}
          _before={{
            content: `""`,
            display: `block`,
            position: `absolute`,
            top: { base: `18%`, md: `12%` },
            left: 0,
            width: `100%`,
            height: 0,
            pt: { base: `78%`, md: `88%` },
            bg: color,
            borderRadius: `xs`
          }}>
          <AspectRatio
            width="100%"
            ratio={3 / 4}
            borderTopLeftRadius="xs"
            borderTopRightRadius="xs"
            borderBottomLeftRadius="xs"
            borderBottomRightRadius="md"
            overflow="hidden">
            <Image image={image} />
          </AspectRatio>
        </Box>
      </Box>
      <VStack
        align="start"
        spacing={{ base: 4, md: 6 }}
        width={{ base: `100%`, md: `45%` }}>
        {tagline && (
          <Heading
            as="p"
            variant="detail"
            size="xs"
            color="secondary.mid-grey"
            mb="0">
            {tagline}
          </Heading>
        )}
        {title && (
          <Heading as="h3" size="xl" mb="0">
            {title}
          </Heading>
        )}
        {text && <Text mb="0">{text}</Text>}
        {cta && cta.title && cta.link && (
          <ButtonCms
            data={cta}
            variant="secondary"
            iconBorderColor="primary.froneri-blue"
            dataLayerType="spotlight">
            {cta.title}
          </ButtonCms>
        )}
      </VStack>
    </Stack>
  );
};

export default SpotlightOnCard;
