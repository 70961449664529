import React from 'react';
import { Icon } from '@chakra-ui/react';

const IceCream3Icon = (props) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.955 3.69905C9.39202 3.69905 7.31428 5.77679 7.31428 8.33981V10.3896C7.53059 10.6732 7.89865 10.9879 8.32806 11.1125C8.5826 11.1864 8.85696 11.1938 9.15266 11.0907C9.45337 10.9859 9.82397 10.7497 10.2335 10.2586C11.5822 8.64149 13.1595 8.16014 14.6404 8.4176C15.3511 8.54118 16.0128 8.82979 16.5958 9.20646V8.33982C16.5958 5.77679 14.5181 3.69905 11.955 3.69905ZM16.5958 11.123C15.9878 10.5204 15.2005 10.0375 14.3834 9.89543C13.4714 9.73686 12.4178 9.98144 11.3855 11.2193C10.8427 11.8701 10.2579 12.294 9.64645 12.5071C9.02995 12.7221 8.43526 12.7055 7.91004 12.5531C7.69979 12.4921 7.50066 12.4095 7.31428 12.3113V16.6408H11.9487L11.955 16.6408L11.9613 16.6408H16.5958V11.123ZM6.53688 18.3398H11.1055V21.1505C11.1055 21.6197 11.4858 22 11.955 22C12.4242 22 12.8045 21.6197 12.8045 21.1505V18.3398H17.3732C17.8822 18.3398 18.2949 17.9272 18.2949 17.4182V8.33982C18.2949 4.83843 15.4564 2 11.955 2C8.45367 2 5.61523 4.83843 5.61523 8.33981V17.4182C5.61523 17.9272 6.02787 18.3398 6.53688 18.3398Z"
    />
  </Icon>
);

export default IceCream3Icon;
