import React from 'react';
import { Box, Heading, Text, Flex, HStack } from '@chakra-ui/react';
import { height as headerHeight } from '@components/organisms/Header';
import CallToActions from '@components/molecules/CallToActions';
import Wrapper from '@components/atoms/Wrapper';
import Image from '@components/atoms/Image';
import RoundEdge from '@components/atoms/RoundEdge';
import getColorFromField from '@helpers/getColorFromField';
import Breadcrumbs from '@components/atoms/Breadcrumbs';

const BrandHero = ({
  data: {
    title,
    text,
    ctas,
    logo,
    secondaryLogo,
    image,
    video,
    brandColor,
    breadcrumbs
  },
  children
}) => {
  const bgColor = getColorFromField(brandColor) || `primary.dark-blue`;
  const logos = [
    { key: `primary`, image: logo },
    { key: `secondary`, image: secondaryLogo }
  ];

  return (
    <Box
      as="section"
      position="relative"
      bg={bgColor}
      pt={headerHeight}
      mb={{ md: 10 }}>
      <Flex
        position={{ md: `absolute` }}
        top="0"
        left="0"
        width="100%"
        height={{ base: `56.25vw`, md: `100%` }}
        mt={{ base: `-1.25rem`, md: `0` }}
        _after={{
          content: { md: `""` },
          position: `absolute`,
          top: 0,
          left: 0,
          display: `block`,
          width: `100%`,
          height: `100%`,
          bgGradient: `linear(to-r, ${bgColor}, transparent)`,
          opacity: 0.8
        }}
        sx={{
          '> .gatsby-image-wrapper': {
            flexGrow: 1
          }
        }}>
        {video && (
          <Box
            as="video"
            src={video.url}
            controls={false}
            autoPlay
            loop
            muted
            playsInline
            objectFit="cover"
            width="100%"
            height="100%"
          />
        )}
        {!video && image && <Image image={image} loading="eager" />}
      </Flex>
      <Box
        position="relative"
        pt={{ base: 12, md: 36 }}
        pb={{ base: 4, md: 14 }}>
        <Wrapper>
          <Box width={{ md: `50%` }} maxWidth={{ md: `30rem` }}>
            {logos.length > 0 && (
              <HStack as="ul" listStyleType="none" mb={{ base: 4, md: 6 }}>
                {logos.map(
                  (l) =>
                    l.image && (
                      <Box
                        key={l.key}
                        as="li"
                        maxWidth={{
                          base: `8.5rem`,
                          md: logos.length === 1 ? `13.625rem` : `10.5rem`
                        }}>
                        <Image image={l.image} />
                      </Box>
                    )
                )}
              </HStack>
            )}
            {title && (
              <Heading as="h1" size="3xl" color="white">
                {title}
              </Heading>
            )}
            {text && (
              <Text color="white" mb="0">
                {text}
              </Text>
            )}
            {ctas && ctas.length > 0 && (
              <CallToActions
                ctas={ctas}
                primaryColor="white"
                primaryIconColor={bgColor}
                secondaryColor="white"
                secondaryIconBorderColor="white"
                mt={{ base: 6, md: 8 }}
                dataLayerType="brand_hero"
              />
            )}
          </Box>
        </Wrapper>
      </Box>
      <RoundEdge type="top" position="relative" />
      {breadcrumbs?.crumbs && (
        <Breadcrumbs
          id="brand-hero-breadcrumbs"
          crumbs={breadcrumbs.crumbs}
          labelOverride={title}
        />
      )}
      {children}
    </Box>
  );
};

export default BrandHero;
