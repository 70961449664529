import React from 'react';
import { Box, Heading, Flex } from '@chakra-ui/react';

const HeadlineAndCallToAction = ({ title, tagline, cta, color, ...props }) => (
  <Flex
    align={{ base: `center`, md: `end` }}
    justify="space-between"
    {...props}>
    <Box pr="4">
      {tagline && (
        <Heading
          as="p"
          variant="detail"
          size="xs"
          color={color || `secondary.mid-grey`}>
          {tagline}
        </Heading>
      )}
      {title && (
        <Heading as="h2" size="3xl" mb="0" lineHeight="2.3rem" color={color}>
          {title}
        </Heading>
      )}
    </Box>
    {cta}
  </Flex>
);

export default HeadlineAndCallToAction;
