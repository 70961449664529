import React, { Suspense, lazy, useState, useEffect } from 'react';

const ReactPlayer = lazy(() => import(`react-player`));

const Loading = () => <p>Loading Video Player...</p>;

const Video = ({ data }) => {
  const [hasWindow, setHasWindow] = useState(false);

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setHasWindow(true);
    }
  }, []);

  return hasWindow === true &&
    typeof data.videoData !== `undefined` &&
    data.videoData !== null &&
    typeof data.videoData.url !== `undefined` &&
    data.videoData.url !== null ? (
    // eslint-disable-next-line react/jsx-indent
    <Suspense fallback={<Loading />}>
      <ReactPlayer url={data.videoData.url} controls />
    </Suspense>
  ) : (
    <p>Video URL invalid.</p>
  );
};

export default Video;
