import React from 'react';
import {
  Flex,
  Box,
  Heading,
  Text,
  AspectRatio,
  useBreakpointValue
} from '@chakra-ui/react';
import Image from '@components/atoms/Image';
import Section from '@components/atoms/Section';
import Wrapper from '@components/atoms/Wrapper';
import ButtonCms from '@components/atoms/ButtonCms';
import getColorFromField from '@helpers/getColorFromField';
import getTextColor from '@helpers/getTextColor';

const TextOnMedia = ({
  data: {
    title,
    tagline,
    content,
    image,
    cta,
    textAlignment,
    backgroundColor,
    showOverlay = true
  }
}) => {
  const bgColor = getColorFromField(backgroundColor) || `primary.froneri-blue`;
  const textColor = getTextColor(backgroundColor);
  const alignRight = textAlignment.toLowerCase() === `right`;
  const gradientPosition = alignRight ? `to-l` : `to-r`;
  const isDesktop = useBreakpointValue({ base: false, md: true });

  return (
    <Section position="relative">
      <Box
        pos={{ base: `static`, md: `absolute` }}
        left="0"
        top="0"
        width="100%"
        height={{ base: `auto`, md: `100%` }}>
        <Box
          w="100%"
          h="100%"
          overflow="hidden"
          borderTopRadius={{ base: `sm`, lg: `md` }}
          pos="relative"
          _after={{
            content: { md: `""` },
            position: `absolute`,
            top: 0,
            left: 0,
            display: `block`,
            width: `100%`,
            height: `100%`,
            bgGradient: showOverlay
              ? `linear(${gradientPosition}, gradient.overlay-dark, transparent)`
              : `unset`,
            opacity: 0.8
          }}>
          <AspectRatio ratio={{ base: 4 / 3, md: 16 / 9 }}>
            <Image
              image={image}
              style={{
                height: isDesktop ? `100vh` : `100%`,
                width: `auto`,
                minHeight: `16rem`
              }}
            />
          </AspectRatio>
        </Box>
      </Box>
      <Flex
        flexFlow={{ base: `column-reverse`, md: `row` }}
        order="1"
        pos="relative"
        overflow="hidden"
        bgColor={{ base: bgColor, md: `transparent` }}>
        <Wrapper maxWidth="none">
          <Flex
            flexDir="column"
            pos="relative"
            zIndex="1"
            py={{ base: 10, md: 16 }}
            h={{ base: `auto`, md: `100vh` }}
            ml={alignRight ? `auto` : `0`}
            maxW="27.75rem"
            maxH="56.25rem">
            {tagline && (
              <Heading as="p" variant="detail" size="xs" color={textColor}>
                {tagline}
              </Heading>
            )}
            <Box mt="auto">
              {title && (
                <Heading as="h2" size="3xl" color={textColor}>
                  {title}
                </Heading>
              )}
              {content && (
                <Text color={textColor} mb="8">
                  {content}
                </Text>
              )}
              {cta && (
                <ButtonCms
                  data={cta}
                  variant="secondary"
                  iconColor={textColor}
                  iconBorderColor="primary.light-grey"
                  color={textColor}
                  dataLayerType="media_block"
                />
              )}
            </Box>
          </Flex>
        </Wrapper>
      </Flex>
    </Section>
  );
};

export default TextOnMedia;
