import { Box, Grid, GridItem, Heading, Text } from '@chakra-ui/react';
import React from 'react';
import getColorFromField from '@helpers/getColorFromField';
import Section from '@components/atoms/Section';
import Wrapper from '@components/atoms/Wrapper';
import ButtonCms from '@components/atoms/ButtonCms';
import FAQItems from '@components/molecules/FAQItems';

const FAQBlock = ({
  data: { tagline, title, cta, text, faqItems, brandColor }
}) => {
  const color = getColorFromField(brandColor);
  const textColor = brandColor ? `#fff` : `primary.dark-blue`;

  return (
    <Section overflow="hidden">
      <Wrapper>
        <Grid
          templateColumns={{
            base: `1fr`,
            lg: `repeat(auto-fit, minmax(14rem, 1fr))`
          }}
          rowGap={8}
          columnGap={[8, 28]}
          color={color ? `#fff` : `primary.dark-blue`}
          backgroundColor={color || `secondary.pale-blue`}
          py={[`16`, `24`]}
          px={[`4`, `4`, `28`]}
          borderRadius="sm">
          <GridItem>
            <Box>
              <Heading
                as="p"
                variant="detail"
                size={[`xs`, `sm`]}
                color={textColor}>
                {tagline}
              </Heading>
              <Heading mt="6" size={[`2xl`, `3xl`]} color={textColor}>
                {title}
              </Heading>
              <Text mt="6" mb="8">
                {text}
              </Text>
              {cta && (
                <ButtonCms
                  data={{ title: cta.title, type: cta.type, link: cta.link }}
                  {...(color ? { color: `white` } : {})}
                />
              )}
            </Box>
          </GridItem>
          <GridItem>
            <FAQItems items={faqItems} dark={!!color} />
          </GridItem>
        </Grid>
      </Wrapper>
    </Section>
  );
};

export default FAQBlock;
