import React from 'react';
import { Icon } from '@chakra-ui/react';

const PlayIcon = (props) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path d="M17.367 11.5055L8.78489 17.4558C8.4533 17.6857 8 17.4484 8 17.0449L8 5.88815C8 5.49841 8.42613 5.25851 8.75934 5.46066L17.3415 10.6672C17.651 10.8549 17.6645 11.2992 17.367 11.5055Z" />
  </Icon>
);

export default PlayIcon;
