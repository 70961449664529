import React from 'react';
import { Icon } from '@chakra-ui/react';

const MenuIcon = (props) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 6.25C3 5.83579 3.33579 5.5 3.75 5.5H20.25C20.6642 5.5 21 5.83579 21 6.25C21 6.66421 20.6642 7 20.25 7H3.75C3.33579 7 3 6.66421 3 6.25Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 12.25C3 11.8358 3.33579 11.5 3.75 11.5H18.09C18.5042 11.5 18.84 11.8358 18.84 12.25C18.84 12.6642 18.5042 13 18.09 13H3.75C3.33579 13 3 12.6642 3 12.25Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 18.25C3 17.8358 3.33579 17.5 3.75 17.5H20.25C20.6642 17.5 21 17.8358 21 18.25C21 18.6642 20.6642 19 20.25 19H3.75C3.33579 19 3 18.6642 3 18.25Z"
    />
  </Icon>
);

export default MenuIcon;
