import React from 'react';
import { AspectRatio, Box, Heading } from '@chakra-ui/react';
import Button from '@components/atoms/Button';
import ConditionalLink from '@components/atoms/ConditionalLink';
import Image from '@components/atoms/Image';
import {
  ArrowRightIcon,
  ExternalLinkIcon,
  PlayIcon
} from '@components/atoms/icons';

const MondelezCard = ({
  data: { strapiComponent, frontFace, title, cta, color },
  alignButton = `start`
}) => {
  let Icon;
  switch (cta?.type) {
    case `internal`:
      Icon = ArrowRightIcon;
      break;
    case `external`:
      Icon = ExternalLinkIcon;
      break;
    case `video`:
      Icon = PlayIcon;
      break;
    default:
      Icon = null;
      break;
  }

  const alignEnd = alignButton === `end`;

  return (
    cta !== null && (
      <Box pos="relative" className="button-hover">
        <ConditionalLink
          cta={cta}
          h="100%"
          w="100%"
          pos="absolute"
          left="0"
          top="0"
          display="inline"
        />
        <Box
          display={alignEnd ? `flex` : `block`}
          flexDir="column"
          maxW="500px"
          color="primary.dark-blue">
          <Box pos="relative">
            <ConditionalLink cta={cta} display="inline">
              <AspectRatio
                maxW="500px"
                ratio={3 / 4}
                overflow="hidden"
                borderRadius="xs"
                borderBottomRightRadius={[`md`, `lg`]}
                display="block">
                <Image image={frontFace} />
              </AspectRatio>
            </ConditionalLink>
          </Box>
          <Box
            px="2"
            display={alignEnd ? `flex` : `block`}
            flexDir="column"
            mt={alignEnd ? `auto` : `0`}
            flexGrow="1">
            {title && (
              <Heading
                mt="7"
                mb="4"
                color={color}
                textDecoration="underline"
                textDecorationColor="transparent"
                textUnderlineOffset="3px"
                textDecorationThickness="2px"
                size={[`md`]}
                sx={{
                  '.button-hover:hover &': {
                    color:
                      strapiComponent !== `elements.team-card` &&
                      `secondary.pink`,
                    textDecorationColor:
                      strapiComponent !== `elements.team-card` &&
                      `secondary.pink`
                  }
                }}>
                <ConditionalLink cta={cta}>{title}</ConditionalLink>
              </Heading>
            )}
            <ConditionalLink cta={cta}>
              <Button
                w="100%"
                mt={[`2`, `4`, `8`]}
                alignSelf="start"
                as={Box}
                variant="primary"
                color={color}
                className="card-cta"
                rightIcon={<Icon />}>
                {cta.title}
              </Button>
            </ConditionalLink>
          </Box>
        </Box>
      </Box>
    )
  );
};

export default MondelezCard;
