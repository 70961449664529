import React from 'react';
import { Icon } from '@chakra-ui/react';

const CloseIcon = (props) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.10572 18.1976C4.81282 17.9047 4.81282 17.4298 5.10572 17.1369L16.773 5.46968C17.0659 5.17679 17.5407 5.17679 17.8336 5.46968C18.1265 5.76258 18.1265 6.23745 17.8336 6.53034L6.16638 18.1976C5.87348 18.4905 5.39861 18.4905 5.10572 18.1976Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.53033 5.46967C5.82322 5.17678 6.2981 5.17678 6.59099 5.46967L18.2583 17.1369C18.5511 17.4298 18.5511 17.9047 18.2583 18.1976C17.9654 18.4905 17.4905 18.4905 17.1976 18.1976L5.53033 6.53033C5.23744 6.23744 5.23744 5.76256 5.53033 5.46967Z"
    />
  </Icon>
);

export default CloseIcon;
