exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-index-js": () => import("./../../../src/pages/articles/index.js" /* webpackChunkName: "component---src-pages-articles-index-js" */),
  "component---src-pages-articles-strapi-article-slug-js": () => import("./../../../src/pages/articles/{StrapiArticle.slug}.js" /* webpackChunkName: "component---src-pages-articles-strapi-article-slug-js" */),
  "component---src-pages-brands-strapi-brand-slug-js": () => import("./../../../src/pages/brands/{StrapiBrand.slug}.js" /* webpackChunkName: "component---src-pages-brands-strapi-brand-slug-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-mondelezicecream-index-js": () => import("./../../../src/pages/mondelezicecream/index.js" /* webpackChunkName: "component---src-pages-mondelezicecream-index-js" */),
  "component---src-pages-mondelezicecream-strapi-mondelez-product-slug-js": () => import("./../../../src/pages/mondelezicecream/{StrapiMondelezProduct.slug}.js" /* webpackChunkName: "component---src-pages-mondelezicecream-strapi-mondelez-product-slug-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-complaint-form-embed-js": () => import("./../../../src/templates/complaint-form-embed.js" /* webpackChunkName: "component---src-templates-complaint-form-embed-js" */),
  "component---src-templates-complaint-form-js": () => import("./../../../src/templates/complaint-form.js" /* webpackChunkName: "component---src-templates-complaint-form-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

