import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { ChevronUpIcon } from '@components/atoms/icons';
import Button from '@components/atoms/Button';

const ScrollToTop = ({ text }) => {
  const [displayButton, setDisplayButton] = useState(false);
  const [initialHeight, setInitialHeight] = useState(0);

  const handleScrollToTop = useCallback(() => {
    if (typeof window !== `undefined`) {
      window.scrollTo({
        top: 0,
        behavior: `smooth`
      });
    }
  }, []);

  useEffect(() => {
    if (typeof document === `undefined`) {
      return () => {};
    }

    const onWindowScroll = () => {
      setDisplayButton(initialHeight > 0 && window.scrollY > initialHeight);
    };

    document.addEventListener(`scroll`, onWindowScroll);

    return () => document.removeEventListener(`scroll`, onWindowScroll);
  }, [initialHeight]);

  useEffect(() => {
    if (typeof window === `undefined`) {
      return () => {};
    }

    const onWindowResized = () => {
      const { innerHeight } = window;
      setDisplayButton(false);
      setInitialHeight(innerHeight);
    };

    window.addEventListener(`resize`, onWindowResized);

    return () => window.removeEventListener(`resize`, onWindowResized);
  }, []);

  useEffect(() => {
    if (typeof window !== `undefined`) {
      const { innerHeight } = window;
      setDisplayButton(window.scrollY > 0);
      setInitialHeight(innerHeight);
    }
  }, []);

  return (
    <Box
      pos="fixed"
      bottom="14"
      right="14"
      zIndex={displayButton ? `100` : `-1`}>
      <Button
        disabled={displayButton === false}
        opacity={displayButton === false ? `0` : `1`}
        transition="opacity .5s"
        boxShadow="xl"
        rightIcon={<ChevronUpIcon />}
        onClick={handleScrollToTop}>
        {text}
      </Button>
    </Box>
  );
};
export default ScrollToTop;
