import React, { useState, useEffect, useCallback, useRef } from 'react';
import { graphql, useStaticQuery, Link as GatsbyLink } from 'gatsby';
import dataLayerPush from '@helpers/dataLayerPush';

import {
  Box,
  Flex,
  VStack,
  Show,
  Hide,
  Accordion,
  Link,
  useDisclosure
} from '@chakra-ui/react';
// import LocaleOptions from '@components/molecules/LocaleOptions';
import NavDrop from '@components/molecules/NavDrop';
import NavDropMobile from '@components/molecules/NavDropMobile';
import Burger from '@components/atoms/Burger';
import Search from '@components/atoms/Search';
import SiteLogo from '@components/atoms/SiteLogo';

export const height = { base: `94px`, md: `108px` };

const Header = () => {
  const { strapiMenus, site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      strapiMenus(attributes: { slug: { eq: "main-header" } }) {
        attributes {
          title
          slug
          items {
            data {
              attributes {
                title
                url
                children {
                  data {
                    attributes {
                      title
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const { isOpen, onToggle } = useDisclosure();
  const [showDesktopNav, setShowDesktopNav] = useState(true);
  const [showMiddle, setShowMiddle] = useState(true);
  const [currentNavItemIndex, setCurrentNavItemIndex] = useState(-1);

  const navItemsRef = useRef([]);

  const handleClick = useCallback((eventName, url, title, navLevel) => {
    dataLayerPush({
      event: eventName,
      interaction: {
        click_text: title,
        link_url: url,
        nav_level: navLevel || 1
      }
    });
  }, []);

  const onRef = (element, elementIndex) => {
    navItemsRef.current[elementIndex] = element;
  };

  useEffect(() => {
    /**
     * onScroll method for the header
     * @param {WheelEvent} event onScroll event
     */
    const onScroll = (event) => {
      if (event.deltaY > 0) setShowDesktopNav(false);
      else if (event.deltaY < 0) setShowDesktopNav(true);
    };

    window.addEventListener(`wheel`, onScroll);

    return () => window.removeEventListener(`wheel`, onScroll);
  }, []);

  useEffect(() => {
    /**
     * onKeyDown method for the header
     * @param {KeyboardEvent} event onKeyDown event
     */
    const onKeyDown = ({ key }) => {
      const menuItems = strapiMenus.attributes.items.data.map(
        (item, index) => index
      );

      if (typeof key === `undefined`) {
        return;
      }

      if (key.toLowerCase() === `arrowleft`) {
        const previousIndex = currentNavItemIndex - 1;
        const previousItem =
          previousIndex < 0 ? menuItems.length - 1 : previousIndex;

        if (navItemsRef.current && navItemsRef.current.length > 0) {
          const currentElement = navItemsRef.current[previousItem];
          currentElement.focus();
        }

        setCurrentNavItemIndex(previousItem);
      }

      if (key.toLowerCase() === `arrowright`) {
        const nextIndex = currentNavItemIndex + 1;
        const nextItem = nextIndex > menuItems.length - 1 ? 0 : nextIndex;

        if (navItemsRef.current && navItemsRef.current.length > 0) {
          const currentElement = navItemsRef.current[nextItem];
          currentElement.focus();
        }

        setCurrentNavItemIndex(nextItem);
      }
    };

    window.addEventListener(`keydown`, onKeyDown);

    return () => window.removeEventListener(`keydown`, onKeyDown);
  }, [currentNavItemIndex, strapiMenus.attributes.items.data]);

  return strapiMenus?.attributes === undefined ||
    strapiMenus.attributes?.items?.data?.length <= 0 ? (
    // eslint-disable-next-line react/jsx-indent
    <Box color="red">Menu render error</Box>
  ) : (
    <Box position="relative">
      <Hide above="lg">
        <Flex
          w="100vw"
          h={height.base}
          alignItems="flex-start"
          justifyContent="space-between"
          position="fixed"
          top="0"
          zIndex="3">
          <Link
            to="/"
            as={GatsbyLink}
            onClick={
              () => handleClick(`nav_logo_click`, `/`, `logo`, `logo`)
              // eslint-disable-next-line react/jsx-curly-newline
            }>
            <Box
              px="5"
              h={height.base}
              pt="30px"
              backgroundColor="#fff"
              borderBottomRightRadius="xs"
              borderBottomLeftRadius="xs"
              boxShadow="lgV">
              <Box w="100%" mt="0.5rem">
                <SiteLogo title={site.siteMetadata.title} width="7.625rem" />
              </Box>
            </Box>
          </Link>
          <Box w="100%">
            <Box h="74px" w="100%" backgroundColor="#fff" boxShadow="lgV" />
            <Box
              h="10px"
              w="calc(100% + 10px)"
              ml="-5px"
              mt="-5px"
              border="5px solid #fff"
              borderBottom="none"
              backgroundColor="transparent"
              borderTopLeftRadius="xs"
              borderTopRightRadius="xs"
            />
          </Box>
          <Box
            px="5"
            h={height.base}
            pt="37px"
            backgroundColor="#fff"
            borderBottomRightRadius="xs"
            borderBottomLeftRadius="xs"
            boxShadow="lgV">
            <Burger isOpen={isOpen} onClick={onToggle} color="#fff" />
          </Box>
        </Flex>
        <Box
          zIndex="2"
          position="fixed"
          top="0"
          right="0"
          left="0"
          bottom="0"
          display={isOpen ? `block` : `none`}
          backgroundColor="primary.dark-blue"
          mt="74px">
          {/* <Box ml="auto" mr="5" mt="50px" w="65%" maxW="250px">
            <LocaleOptions light />
          </Box> */}
          <Search w="calc(100% - 2rem)" mx="4" mt="12" />
          <Flex flexDir="column" mt="1rem">
            <Accordion allowToggle>
              {strapiMenus.attributes.items.data.map((category) => (
                <NavDropMobile
                  key={category.attributes.title}
                  menuItem={category}
                />
              ))}
            </Accordion>
          </Flex>
        </Box>
      </Hide>
      <Show above="lg">
        <Flex w="100%" justifyContent="center">
          <Flex
            w="100vw"
            maxW="1320px"
            h={height.md}
            alignItems="center"
            justifyContent="space-between"
            px="44px"
            pr={{ base: null, xl: `4` }}
            position="fixed"
            top={showDesktopNav ? `0px` : `-${height.md}`}
            zIndex="2"
            background="#fff"
            borderBottomLeftRadius="md"
            borderBottomRightRadius="xs"
            boxShadow="lg"
            transition="top 0.5s ease">
            <Link
              to="/"
              as={GatsbyLink}
              onClick={
                () => handleClick(`nav_logo_click`, `/`, `logo`, `logo`)
                // eslint-disable-next-line react/jsx-curly-newline
              }>
              <Box w="180px" pr={{ base: `0`, xl: `4` }}>
                <SiteLogo title={site.siteMetadata.title} />
              </Box>
            </Link>
            <Flex mt="0.4rem" ml="auto" justifyContent="end" role="navigation">
              {showMiddle &&
                strapiMenus.attributes.items.data.map(
                  (category, elementIndex) => (
                    <Box role="presentation" key={category.attributes.title}>
                      <NavDrop
                        ref={(element) => onRef(element, elementIndex)}
                        menuItem={category}
                        show={!showDesktopNav}
                      />
                    </Box>
                  )
                )}
              <Hide above="xl">
                <Search
                  compact
                  onToggle={setShowMiddle}
                  position={showMiddle ? `relative` : `absolute`}
                  right={showMiddle ? `0` : `2.75rem`}
                  top={showMiddle ? `0` : `2.7rem`}
                />
              </Hide>
            </Flex>
            <Show above="xl">
              <VStack mt="0.1rem" mb="0" justifyContent="end">
                {/* <LocaleOptions /> */}
                <Search />
              </VStack>
            </Show>
            {/* <Hide above="xl">
              <Box position="absolute" top="4" right="12" w="60">
                <LocaleOptions />
              </Box>
            </Hide> */}
          </Flex>
        </Flex>
      </Show>
    </Box>
  );
};

export default Header;
