import React, { useCallback } from 'react';
import {
  Box,
  Heading,
  Flex,
  Link,
  HStack,
  useBreakpointValue
} from '@chakra-ui/react';
import dataLayerPush from '@helpers/dataLayerPush';
import { height as headerHeight } from '@components/organisms/Header';
import Wrapper from '@components/atoms/Wrapper';
import Image from '@components/atoms/Image';
import RoundEdge from '@components/atoms/RoundEdge';
import Breadcrumbs from '@components/atoms/Breadcrumbs';

const ArticleHero = ({
  data: { publishDate, tags, title, image, breadcrumbs = [] }
}) => {
  const isDesktop = useBreakpointValue({ base: false, md: true });

  const bgColor = `primary.dark-blue`;

  const handleClick = useCallback((eventName, url, clickText) => {
    dataLayerPush({
      event: eventName,
      interaction: {
        click_text: clickText,
        destination_url: url
      }
    });
  }, []);

  return (
    <Box
      as="section"
      position="relative"
      bg={bgColor}
      pt={headerHeight}
      mb={{ md: 10 }}>
      <Flex
        position={{ md: `absolute` }}
        top="0"
        left="0"
        width="100%"
        height={{ base: `56.25vw`, md: `100%` }}
        mt={{ base: `-1.25rem`, md: `0` }}
        _after={{
          content: { md: `""` },
          position: `absolute`,
          top: 0,
          left: 0,
          display: `block`,
          width: `100%`,
          height: `100%`,
          bg: bgColor,
          opacity: 0.8
        }}
        sx={{
          '> .gatsby-image-wrapper': {
            flexGrow: 1
          }
        }}>
        {image && <Image image={image} loading="eager" />}
      </Flex>
      <Box
        position="relative"
        pt={{ base: 12, md: 36 }}
        pb={{ base: 4, md: 14 }}>
        <Wrapper>
          <Flex>
            {publishDate && (
              <Heading as="p" variant="detail" size="xs" color="white">
                {publishDate}
              </Heading>
            )}
            <HStack ml="auto" spacing="4">
              {isDesktop &&
                tags &&
                tags.length > 0 &&
                tags.map((tag) => (
                  <Link
                    key={tag.tagId}
                    href={`/articles/?tag=${tag.tagId}`}
                    p="0.5rem 1.375rem"
                    bgColor="white"
                    color="primary.dark-blue"
                    fontSize="sm"
                    borderRadius="sm"
                    _hover={{
                      bgColor: `secondary.pink`,
                      color: `white`
                    }}
                    onClick={
                      () =>
                        handleClick(
                          `taxonomy`,
                          `/articles/?tag=${tag.tagId}`,
                          tag.tagName
                        )
                      // eslint-disable-next-line react/jsx-curly-newline
                    }>
                    {tag.tagName}
                  </Link>
                ))}
            </HStack>
          </Flex>
          <Box
            width={{ md: `75%` }}
            maxWidth={{ md: `48rem` }}
            pt={{ base: `0`, md: `3.313rem` }}
            pb={{ base: `4`, md: `5rem` }}>
            {title && (
              <Heading as="h1" size="3xl" color="white">
                {title}
              </Heading>
            )}
          </Box>
        </Wrapper>
      </Box>
      <RoundEdge type="top" position="relative" />
      {breadcrumbs.crumbs && (
        <Breadcrumbs
          id="article-hero-breadcrumbs"
          crumbs={breadcrumbs.crumbs}
          labelOverride={title}
        />
      )}
    </Box>
  );
};

export default ArticleHero;
