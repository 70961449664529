import React, { useCallback, useEffect, forwardRef, useState } from 'react';
import { navigate } from 'gatsby';
import {
  Button as ChakraButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Link,
  Box,
  useDisclosure
} from '@chakra-ui/react';
import Button from '@components/atoms/Button';
import ChevronDownIcon from '@components/atoms/icons/ChevronDownIcon';
import ChevronUpIcon from '@components/atoms/icons/ChevronUpIcon';
import ArrowRightIcon from '@components/atoms/icons/ArrowRightIcon';
import dataLayerPush from '@helpers/dataLayerPush';
import { stringToId } from '../../helpers/stringFormatters';

const AsButton = forwardRef(({ isOpen, ...props }, ref) => {
  return (
    <ChakraButton
      ref={ref}
      isActive={isOpen}
      rightIcon={
        isOpen ? (
          <ChevronUpIcon
            style={{
              width: `1.25rem`,
              height: `1.25rem`,
              marginLeft: `-0.25rem`
            }}
          />
        ) : (
          <ChevronDownIcon
            style={{
              width: `1.25rem`,
              height: `1.25rem`,
              marginLeft: `-0.25rem`
            }}
            color="primary.froneri-blue"
            sx={{ 'button:hover &': { color: `secondary.pink` } }}
          />
        )
      }
      {...props}
    />
  );
});

const AsLink = forwardRef(
  (
    {
      children,
      url,
      title,

      onClick = () => {},
      onKeyDown = () => {},
      ...props
    },
    ref
  ) => {
    const handleClick = useCallback(() => {
      dataLayerPush({
        event: `nav_button_click`,
        interaction: {
          click_text: title,
          link_url: url,
          nav_level: 1
        }
      });
    }, [title, url]);

    return (
      <Link
        ref={ref}
        display="flex"
        href={url}
        title={title}
        h="14"
        px="1.2rem"
        py="0.5"
        color="primary.dark-blue"
        fontFamily="heading"
        fontWeight="600"
        lineHeight="1.2"
        borderRadius="xs"
        onClick={handleClick}
        sx={{
          span: {
            alignSelf: `center`
          }
        }}
        {...props}>
        {children}
      </Link>
    );
  }
);

const NavDrop = forwardRef(({ menuItem, show }, ref) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [ctaFocused, setCtaFocused] = useState(false);

  const renderAsButton = menuItem.attributes.children.data.length > 1;
  const navProps =
    renderAsButton === true
      ? { isOpen }
      : {
          url: menuItem.attributes.url,
          title: menuItem.attributes.title
        };

  useEffect(() => {
    if (show) onClose();
  }, [show, onClose]);

  const handleNavigate = useCallback((url, title, navLevel) => {
    dataLayerPush({
      event: `nav_button_click`,
      interaction: {
        click_text: title,
        link_url: url,
        nav_level: navLevel || 1
      }
    });

    navigate(url);
  }, []);

  const handleCTAClicked = useCallback(
    (url, title, navLevel) => {
      if (ctaFocused) {
        handleNavigate(url, title, navLevel);
      }
    },
    [handleNavigate, ctaFocused]
  );

  const handleCTAFocused = useCallback(() => setCtaFocused(true), []);
  const handleCTABlur = useCallback(() => setCtaFocused(false), []);

  return (
    <Menu isOpen={isOpen} onClose={onClose}>
      <MenuButton
        ref={ref}
        role="menuitem"
        as={renderAsButton === true ? AsButton : AsLink}
        color={isOpen ? `secondary.pink` : `primary.dark-blue`}
        fontSize={{ base: `sm!important`, xl: `md!important` }}
        pr={{ base: `0!important`, xl: `1.125rem!important` }}
        pl={{ base: `5!important`, xl: `0!important` }}
        _hover={{ color: `secondary.pink` }}
        onClick={() => {
          if (menuItem.attributes.children.data.length > 1) {
            return isOpen ? onClose() : onOpen();
          }
          return handleNavigate(
            menuItem.attributes.url,
            menuItem.attributes.title
          );
        }}
        {...navProps}>
        {menuItem.attributes.title}
      </MenuButton>
      {menuItem.attributes.children.data.length > 1 && (
        <MenuList
          role="menu"
          borderRadius="xs"
          border="none"
          boxShadow="lg"
          mt="7"
          ml="6"
          pt="2.5"
          position="relative">
          <Box
            width="0"
            height="0"
            borderLeft="10px solid transparent"
            borderRight="10px solid transparent"
            borderBottom="10px solid #fff"
            position="absolute"
            top="-10px"
            left="10%"
          />
          {menuItem.attributes.children.data.map((link, index) => (
            <MenuItem
              // eslint-disable-next-line react/no-array-index-key
              key={`${stringToId(link.attributes.title)}-${index}`}
              role="presentation"
              bg="none"
              px="1.2rem"
              transition={`opacity 0.05s ease-in ${index * 0.05}s`}
              opacity={isOpen ? `1` : `0`}
              borderRadius="xs"
              border="2px solid transparent"
              as={Link}
              color="primary.dark-blue"
              textDecoration="underline"
              textDecorationColor="transparent"
              fontSize={[`sm`, undefined, `md`]}
              fontWeight="800"
              py="0.5"
              _hover={{
                bg: `none`,
                border: `2px solid transparent`,
                textDecoration: `underline`,
                color: `secondary.pink`
              }}
              onClick={
                () => handleNavigate(link.attributes.url, link.attributes.title)
                // eslint-disable-next-line react/jsx-curly-newline
              }>
              {link.attributes.title}
            </MenuItem>
          ))}
          <MenuItem
            as="div"
            role="presentation"
            cursor="default"
            _hover={{
              background: `transparent`
            }}
            pointerEvents="none"
            onClick={
              () =>
                handleCTAClicked(
                  menuItem.attributes.url,
                  menuItem.attributes.title,
                  2
                )
              // eslint-disable-next-line react/jsx-curly-newline
            }
            onFocus={handleCTAFocused}
            onBlur={handleCTABlur}>
            <Button
              m="4"
              ml="2"
              cursor="pointer"
              role="menuitem"
              pointerEvents="auto"
              rightIcon={
                <ArrowRightIcon color="primary.froneri-blue" h="5" w="5" />
              }
              opacity={isOpen ? `1` : `0`}
              transition={`opacity 0.05s ease-in ${
                menuItem.attributes.children.data.length * 0.05
              }s`}
              onClick={
                () =>
                  handleNavigate(
                    menuItem.attributes.url,
                    menuItem.attributes.title,
                    2
                  )
                // eslint-disable-next-line react/jsx-curly-newline
              }>
              {menuItem.attributes.title}
            </Button>
          </MenuItem>
        </MenuList>
      )}
    </Menu>
  );
});

export default NavDrop;
