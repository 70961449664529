import React from 'react';
import { Icon } from '@chakra-ui/react';

const ChevronDownIcon = (props) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5096 13.0259L7.0545 7.41478C6.73132 7.08235 6.19412 7.09339 5.88486 7.43882L4.9986 8.42875C4.71793 8.74225 4.72809 9.21959 5.02186 9.52086L12.5096 17.2L19.9794 9.52085C20.2726 9.21949 20.2824 8.74264 20.002 8.42941L19.116 7.43984C18.8065 7.09404 18.2685 7.08341 17.9455 7.41671L12.5096 13.0259Z"
    />
  </Icon>
);

export default ChevronDownIcon;
