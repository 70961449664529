import React from 'react';
import { ButtonGroup } from '@chakra-ui/react';
import Button from '@components/atoms/Button';
import { ArrowLeftIcon, ArrowRightIcon } from '@components/atoms/icons';

const hoverStyles = {
  bg: null
};

const iconHoverStyles = {
  '.chakra-button:hover &': {
    color: `secondary.pink`
  }
};

const SliderArrows = ({ color = `primary.dark-blue` }) => {
  const iconColor = color === `white` ? `primary.dark-blue` : `white`;
  return (
    <ButtonGroup
      mt={{ base: `auto`, md: `0` }}
      sx={{
        '> .swiper-button-disabled[disabled]': {
          opacity: 1
        },
        '> .swiper-button-disabled[disabled] > svg': {
          opacity: 0.5
        }
      }}
      isAttached>
      <Button
        className="prev"
        color={color}
        icon={<ArrowLeftIcon color={iconColor} sx={iconHoverStyles} />}
        pr="1 !important"
        _hover={hoverStyles}
      />
      <Button
        className="next"
        color={color}
        icon={<ArrowRightIcon color={iconColor} sx={iconHoverStyles} />}
        pl="1 !important"
        _hover={hoverStyles}
      />
    </ButtonGroup>
  );
};

export default SliderArrows;
