import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import {
  AspectRatio,
  Stack,
  Box,
  Heading,
  Text,
  Image
} from '@chakra-ui/react';
import Button from '@components/atoms/Button';
import { ArrowRightIcon } from '@components/atoms/icons';
import prefixUploadUrl from '@helpers/prefixUploadUrl';

const Tease = ({ title, text, image, cta }) => {
  return (
    <Stack
      as={cta?.link ? GatsbyLink : null}
      to={cta?.link}
      className="button-hover"
      direction={{ base: `column`, md: `row-reverse` }}
      justify="left"
      spacing={{ base: 8, md: 0 }}
      width="100%">
      {image && image.formats.medium && (
        <Box width={{ md: `18rem` }} flex="0 0 auto">
          <AspectRatio
            ratio={3 / 2}
            overflow="hidden"
            borderRadius="xs"
            borderBottomRightRadius="md">
            <Image
              src={prefixUploadUrl(image.formats.medium.url).replace(
                /backend./g,
                `www.`
              )}
              alt={image.alternativeText}
            />
          </AspectRatio>
        </Box>
      )}
      <Box width={{ md: `calc(100% - 18rem)` }} pr={{ md: 12 }}>
        {title && (
          <Heading
            size="lg"
            color="secondary.pink"
            textDecoration="underline"
            textUnderlineOffset="3px"
            textDecorationThickness="2px">
            {title}
          </Heading>
        )}
        {text && <Text>{text}</Text>}
        {cta?.link && (
          <Button
            as="span"
            variant="secondary"
            iconBorderColor="primary.froneri-blue"
            rightIcon={<ArrowRightIcon />}>
            {cta.title}
          </Button>
        )}
      </Box>
    </Stack>
  );
};

export default Tease;
