import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Flex,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  Button,
  Heading,
  useDisclosure,
  Text,
  PopoverAnchor,
  Portal
} from '@chakra-ui/react';
import { CloseIcon } from '@components/atoms/icons';
import ButtonCms from '@components/atoms/ButtonCms';
import MapMarkerImage from '@components/atoms/MapMarkerImage';

const MapMarker = ({ marker, zoom, ...rest }) => {
  const [markerPos, setMarkerPos] = useState({ x: 0, y: 0 });
  const { isOpen, onClose, onOpen } = useDisclosure();
  const pinRef = useRef(null);
  const [offsetContentVertical, setOffsetContentVertical] = useState(0);

  useEffect(() => {
    setMarkerPos({
      x: marker.xAxis,
      y: marker.yAxis
    });

    if (isOpen && pinRef.current) {
      const { height } = pinRef.current.getBoundingClientRect();
      setOffsetContentVertical(height / zoom - 50);
    }
  }, [marker, zoom, isOpen]);

  return (
    <Popover
      onClose={onClose}
      onOpen={onOpen}
      isOpen={isOpen}
      offset={[0, offsetContentVertical]}>
      <PopoverAnchor>
        <Box
          className="map-marker"
          position="absolute"
          w="2%"
          minW="14"
          maxW="18"
          pointerEvents="none"
          transform="translate(-50%, -50%)"
          top={`${markerPos.y}%`}
          left={`${markerPos.x}%`}>
          <Box ref={pinRef} transform={`scale(${1 / zoom})`} {...rest}>
            <PopoverTrigger>
              <Button px="0!important">
                <MapMarkerImage
                  onClick={onOpen}
                  sx={{
                    svg: {
                      fill: isOpen ? `secondary.pink` : `primary.dark-blue`
                    },
                    _hover: {
                      svg: {
                        fill: `secondary.pink`
                      }
                    }
                  }}
                />
              </Button>
            </PopoverTrigger>
          </Box>
        </Box>
      </PopoverAnchor>
      <Portal>
        <PopoverContent
          cursor="default"
          backgroundColor="white"
          borderRadius="xs"
          boxShadow="lg"
          w="72"
          p="6">
          <PopoverArrow />
          <Flex justifyContent="space-between">
            <Heading maxWidth="13rem" size="2xl" mb="0" mt="2">
              {marker.title}
            </Heading>
            <Button
              onClick={onClose}
              onTouchStart={onClose}
              backgroundColor="primary.dark-blue"
              width="2.125rem"
              height="2.125rem!important"
              p="0!important"
              borderRadius=".25rem"
              mt="-3"
              mr="-3"
              _hover={{ backgroundColor: `secondary.pink` }}>
              <CloseIcon color="white" w="6" h="6" />
            </Button>
          </Flex>
          <Text mt="3" mb={marker.cta ? `8` : `0`}>
            {marker.text}
          </Text>
          {marker.cta && (
            <ButtonCms
              className="map-marker-btn"
              data={{ ...marker.cta }}
              w="min-content"
            />
          )}
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default MapMarker;
