import React from 'react';
import { Icon } from '@chakra-ui/react';

const ExternalLinkIcon = (props) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.524 2.56251C12.524 2.12705 12.877 1.77405 13.3125 1.77405H18.4375C18.873 1.77405 19.226 2.12705 19.226 2.56251V7.68751C19.226 8.12296 18.873 8.47597 18.4375 8.47597C18.002 8.47597 17.649 8.12296 17.649 7.68751V3.35097H13.3125C12.877 3.35097 12.524 2.99796 12.524 2.56251Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.77083 5.87501C4.51667 5.87501 4.27291 5.97598 4.09319 6.1557C3.91347 6.33542 3.8125 6.57918 3.8125 6.83334V16.2292C3.8125 16.4833 3.91347 16.7271 4.09319 16.9068C4.27291 17.0865 4.51667 17.1875 4.77083 17.1875H14.1667C14.4208 17.1875 14.6646 17.0865 14.8443 16.9068C15.024 16.7271 15.125 16.4833 15.125 16.2292V11.1042C15.125 10.69 15.4608 10.3542 15.875 10.3542C16.2892 10.3542 16.625 10.69 16.625 11.1042V16.2292C16.625 16.8812 16.366 17.5065 15.905 17.9675C15.4439 18.4285 14.8187 18.6875 14.1667 18.6875H4.77083C4.11884 18.6875 3.49356 18.4285 3.03253 17.9675C2.5715 17.5065 2.3125 16.8812 2.3125 16.2292V6.83334C2.3125 6.18135 2.5715 5.55607 3.03253 5.09504C3.49356 4.63401 4.11884 4.37501 4.77083 4.37501H9.89583C10.31 4.37501 10.6458 4.7108 10.6458 5.12501C10.6458 5.53922 10.31 5.87501 9.89583 5.87501H4.77083Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.9679 2.03218C19.2608 2.32507 19.2608 2.79995 18.9679 3.09284L9.57203 12.4887C9.27914 12.7816 8.80426 12.7816 8.51137 12.4887C8.21848 12.1958 8.21848 11.7209 8.51137 11.428L17.9072 2.03218C18.2001 1.73929 18.675 1.73929 18.9679 2.03218Z"
    />
  </Icon>
);

export default ExternalLinkIcon;
