import React, {
  useState,
  useMemo,
  useEffect,
  useRef,
  useCallback
} from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { SimpleGrid, VStack, Flex, Text, Box } from '@chakra-ui/react';
import Section from '@components/atoms/Section';
import Wrapper from '@components/atoms/Wrapper';
import FilterBar from '@components/molecules/FilterBar';
import Tease from '@components/molecules/Tease';
import Card from '@components/molecules/Card';
import Pagination from '@components/molecules/Pagination';
import Breadcrumbs from '@components/atoms/Breadcrumbs';
import { siteUrl } from '@helpers/environment';
import scrollToPromise from '@helpers/scrollToPromise';
import linkResolver from '@helpers/linkResolver';
import dataLayerPush from '@helpers/dataLayerPush';
import { someAreNil, isNil } from '@helpers/dataHelpers';

/*
 * Fetches search results from the OpenSearch API. Wrapped in a try-catch
 * without error throw to avoid rendering being blocked on certain devices.
 */
const fetchResults = async (query) => {
  try {
    const response = await fetch(`${siteUrl}/opensearch/?query=${query}`);

    if (response.status !== 200) {
      return [];
    }

    const { hits } = await response.json();
    return typeof hits === `undefined`
      ? []
      : hits.map(({ index, id, source }) => {
          return {
            contentType: index,
            id,
            entry: {
              ...source
            }
          };
        });
  } catch (error) {
    console.error(error);
    return [];
  }
};

/*
 * Checks if results should be fetched from the OpenSearch API
 * or the static entries should be used instead.
 */
const maybeFetchResults = async (query, staticEntries) => {
  const isQueryEmpty = typeof query === `undefined` || query.length === 0;
  const staticIsNilOrEmpty =
    isNil(staticEntries) === true || staticEntries.length === 0;

  if (staticIsNilOrEmpty === false && isQueryEmpty) {
    return staticEntries;
  }

  let results = await fetchResults(query);

  if (staticIsNilOrEmpty === false) {
    const resultIDs = results.map(({ id }) => id);
    results = staticEntries.filter(({ id }) => resultIDs.indexOf(id) !== -1);
  }

  return results;
};

/*
 * Sorts results by their publish date.
 * If the manual publish date field exists it will be used instead of the actual publish date.
 */
const sortResultsByDate = (results, sort = `desc`) => {
  const sortedResults = results.sort(({ entry: a }, { entry: b }) => {
    const dateA = new Date(
      a.publishDate ? `${a.publishDate} 00:00` : a.publishedAt
    );
    const dateB = new Date(
      b.publishDate ? `${b.publishDate} 00:00` : b.publishedAt
    );
    return dateB - dateA;
  });

  if (sort === `asc`) {
    sortedResults.reverse();
  }

  return sortedResults;
};

/*
 * Performs the sort and filter operations on the results.
 * Results will also be grouped into pages.
 */
const sortAndFilterResults = (results, filterValues, perPage) => {
  // Filter results to match criteria.
  let newResults =
    isNil(filterValues.tag) || filterValues.tag.length === 0
      ? results.slice()
      : results.slice().filter(({ entry }) => {
          if (Array.isArray(entry.tags)) {
            const tags = entry.tags.map(({ tagId }) => tagId);
            return filterValues.tag.some((tagId) => tags.indexOf(tagId) !== -1);
          }
          return false;
        });

  // Sort order of results by date.
  if (filterValues.sort === `desc` || filterValues.sort === `asc`) {
    newResults = sortResultsByDate(newResults, filterValues.sort);
  }

  // Group results into pages.
  const numberOfPages = Math.ceil(newResults.length / perPage);
  return Array.from({ length: numberOfPages }).map((_, i) => {
    const skip = i * perPage;
    return newResults.slice(skip, skip + perPage);
  });
};

const SearchListing = ({
  staticEntries = [],
  sortOptions,
  perPage = 9,
  hasCards = false,
  title,
  ctaText,
  resultsText,
  noResultsText,
  breadcrumbs,
  excludeFilterOperators = [],
  ...props
}) => {
  const { strapiSearchAndFilters, allStrapiTag } = useStaticQuery(graphql`
    {
      strapiSearchAndFilters {
        id
        filterText
        sortText
        sortRelevanceText
        sortDescText
        sortAscText
      }
      allStrapiTag {
        nodes {
          tagId
          tagName
        }
      }
    }
  `);

  const filters = useMemo(() => {
    const translations = strapiSearchAndFilters || {};
    const sortOptionsToUse = Array.isArray(sortOptions)
      ? sortOptions
      : [`rel`, `desc`, `asc`];
    return [
      {
        id: `filter-sort-${translations.id}`,
        label: translations.sortText,
        name: `sort`,
        type: `radio`,
        items: sortOptionsToUse.map((value) => {
          let label = null;
          switch (value) {
            case `rel`:
              label = translations.sortRelevanceText;
              break;
            case `desc`:
              label = translations.sortDescText;
              break;
            case `asc`:
              label = translations.sortAscText;
              break;
            default:
              label = ``;
              break;
          }
          return { label, value };
        }),
        defaultValue: sortOptionsToUse[0]
      },
      {
        id: `filter-tag-${translations.id}`,
        label: translations.filterText,
        name: `tag`,
        type: `checkbox`,
        items: allStrapiTag.nodes.map((tag) => ({
          label: tag.tagName,
          value: tag.tagId
        })),
        defaultValue: []
      }
    ].filter(
      ({ name }) =>
        !excludeFilterOperators.some((filterName) => filterName === name)
    );
  }, [
    sortOptions,
    strapiSearchAndFilters,
    allStrapiTag,
    excludeFilterOperators
  ]);

  const defaultFilterValues = useMemo(
    () =>
      filters.reduce((values, { name, defaultValue }) => {
        return {
          ...values,
          [name]: defaultValue
        };
      }, {}),
    [filters]
  );

  const filterBarRef = useRef(null);

  const [pagedResults, setPagedResults] = useState([]);
  const [page, setPage] = useState(1);
  const [currentResultSet, setCurrentResultSet] = useState([]);
  const [paginationItems, setPaginationItems] = useState([]);
  const [query, setQuery] = useState(``);
  const [initialQuery, setInitialQuery] = useState(``);
  const [filterValues, setFilterValues] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [numPages, setNumPages] = useState(0);
  const [numResults, setNumResults] = useState(0);

  /*
   * Updates the active page when the user clicks pagination.
   */
  const changePage = useCallback(
    async (pageNum) => {
      const params = new URLSearchParams(window.location.search);
      if (pageNum === 1) {
        params.delete(`page`);
      } else {
        params.set(`page`, pageNum);
      }
      const queryString = params.toString();

      // Turn on loading state.
      setIsLoading(true);

      // Set URL.
      const { origin, pathname } = window.location;
      window.history.pushState(
        ``,
        ``,
        `${origin}${pathname}${queryString ? `?${queryString}` : ``}`
      );

      // Scroll to top of listing.
      await scrollToPromise(filterBarRef.current);

      // Update page.
      setPage(pageNum);

      // Update result set.
      if (pageNum > 0 && pagedResults) {
        setCurrentResultSet(pagedResults[pageNum - 1]);
      }

      // Turn off loading state.
      setIsLoading(false);
    },
    [pagedResults]
  );

  /*
   * Refreshes results after update operation
   */
  const refreshResults = useCallback(
    async (payload) => {
      const newResults = await maybeFetchResults(payload.query, staticEntries);

      const newPagedResults = sortAndFilterResults(
        newResults,
        payload.filterValues,
        perPage
      );

      const newPageCount = newPagedResults?.length || 0;
      const newPaginationItems = Array.from(
        { length: newPageCount },
        (_, i) => i + 1
      );

      setFilterValues(payload.filterValues);
      setQuery(payload.query);
      setPage(payload.page);
      setPagedResults(newPagedResults);
      setPaginationItems(newPaginationItems);
      setNumPages(newPageCount);

      if (newPageCount > 0 && newPagedResults) {
        setCurrentResultSet(newPagedResults[payload.page - 1]);
      }

      const numberOfResults =
        newPageCount > 0 && newPagedResults.length > 0
          ? newPagedResults[newPageCount - 1].length +
            (newPageCount - 1) * perPage
          : 0;

      setNumResults(numberOfResults);

      dataLayerPush({
        event: `search`,
        interaction: {
          search_term: payload.query,
          results_returned: newPagedResults?.length || 0
        }
      });
    },
    [perPage, staticEntries]
  );

  /**
   * Updates displayed results when the user submits filter options.
   */
  const submitFilter = useCallback(
    async (newFilterValues, newQuery) => {
      // Build new URL query string.
      const params = new URLSearchParams(window.location.search);
      if (typeof newQuery === `undefined` || newQuery.length === 0) {
        params.delete(`query`);
      } else {
        params.set(`query`, newQuery);
      }
      filters.forEach(({ name, type }) => {
        const value = newFilterValues[name];
        if (value !== defaultFilterValues[name] && value.length > 0) {
          params.set(
            name,
            type === `checkbox` && Array.isArray(value)
              ? value.toString(`,`)
              : value
          );
        } else {
          params.delete(name);
        }
      });
      params.delete(`page`);
      const queryString = params.toString();

      // Turn on loading state.
      setIsLoading(true);

      // Set URL.
      const { origin, pathname } = window.location;
      window.history.pushState(
        ``,
        ``,
        `${origin}${pathname}${queryString ? `?${queryString}` : ``}`
      );

      // Scroll to top of listing.
      await scrollToPromise(filterBarRef.current);

      // Refresh result states
      await refreshResults({
        filterValues: newFilterValues,
        query: newQuery,
        page: 1
      });

      // Turn off loading state.
      setIsLoading(false);
    },
    [filters, refreshResults, defaultFilterValues]
  );

  /**
   * Updates displayed results when the user submits search input.
   */
  const submitQuery = useCallback(
    async (newQuery) => {
      const params = new URLSearchParams(window.location.search);
      if (typeof newQuery === `undefined` || newQuery.length === 0) {
        params.delete(`query`);
      } else {
        params.set(`query`, newQuery);
      }
      params.delete(`page`);
      const queryString = params.toString();

      // Turn on loading state.
      setIsLoading(true);

      // Set URL.
      const { origin, pathname } = window.location;
      window.history.pushState(
        ``,
        ``,
        `${origin}${pathname}${queryString ? `?${queryString}` : ``}`
      );

      // Scroll to top of listing.
      await scrollToPromise(filterBarRef.current);

      // Refresh result states
      await refreshResults({
        filterValues,
        query: newQuery,
        page: 1
      });

      // Turn off loading state.
      setIsLoading(false);
    },
    [filterValues, refreshResults]
  );

  /*
   * On page load set initial results based on URL parameters.
   */
  useEffect(() => {
    const setInitialResults = async () => {
      const params = new URLSearchParams(window.location.search);
      const newPage = parseInt(params.get(`page`), 10) || 1;
      const newQuery = params.get(`query`) || ``;

      const newFilterValues = filters.reduce((values, { name, type }) => {
        let value = params.get(name);
        if (typeof value === `string` && value.length > 0) {
          value = type === `checkbox` ? value.split(`,`) : value;
        } else {
          value = defaultFilterValues[name];
        }
        return {
          ...values,
          [name]: value
        };
      }, []);

      // Turn on loading state.
      setIsLoading(true);
      setInitialQuery(newQuery);

      // Refresh result states
      await refreshResults({
        filterValues: newFilterValues,
        query: newQuery,
        page: newPage
      });

      // Turn off loading state.
      setIsLoading(false);
    };

    setInitialResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // defaultFilterValues, filters, staticEntries all cause an infinite loop

  return (
    <Section {...props}>
      {breadcrumbs.crumbs && (
        <Breadcrumbs
          id="search-listing-breadcrumbs"
          pb="12"
          crumbs={breadcrumbs.crumbs}
          labelOverride={title}
        />
      )}
      <Wrapper>
        <FilterBar
          ref={filterBarRef}
          filters={filters}
          activeValues={filterValues}
          initialQuery={initialQuery}
          title={title.replace(`{query}`, `'${query}'`)}
          tagline={
            pagedResults !== null && typeof resultsText === `string`
              ? resultsText
                  .replace(`{showing}`, pagedResults[page - 1]?.length || 0)
                  .replace(`{total}`, numResults)
              : null
          }
          onSubmitFilter={submitFilter}
          onSubmitQuery={submitQuery}
          mb={{ base: 12, md: 20 }}
        />
        {pagedResults !== null && pagedResults.length > 0 ? (
          <Box
            opacity={isLoading ? 0.5 : null}
            pointerEvents={isLoading ? `none` : null}>
            {hasCards === true ? (
              <SimpleGrid columns={{ base: 1, md: 3 }} spacing="12" h="100%">
                {currentResultSet?.map(({ contentType, id, entry }) => (
                  <Card
                    key={id}
                    data={{
                      strapiComponent: `elements.news-card`,
                      title: entry.title,
                      date: entry.publishDate,
                      text: entry.text,
                      frontFace: entry.thumbnail,
                      cta: {
                        type: `internal`,
                        title: ctaText,
                        link: linkResolver(entry.slug, contentType),
                        extraDataLayerProps: {
                          search_term: query,
                          results_returned: pagedResults.length,
                          result_clicked: entry.title
                        }
                      }
                    }}
                    alignButton="end"
                  />
                ))}
                {(typeof currentResultSet === `undefined` ||
                  pagedResults.length === 0) &&
                  noResultsText && <Text>{noResultsText}</Text>}
              </SimpleGrid>
            ) : (
              <VStack align="left" spacing={{ base: 12, md: 20 }}>
                {currentResultSet?.map(({ contentType, id, entry }) => {
                  return someAreNil([entry.slug]) === false ? (
                    <React.Fragment key={id}>
                      <Tease
                        title={entry.title}
                        text={entry.excerpt}
                        image={entry.thumbnail}
                        cta={{
                          title: ctaText,
                          link: linkResolver(entry.slug, contentType)
                        }}
                      />
                    </React.Fragment>
                  ) : (
                    <Box color="red">
                      There was an error rendering this entry.
                    </Box>
                  );
                })}
                {(typeof pagedResults[page - 1] === `undefined` ||
                  pagedResults.length === 0) &&
                  noResultsText && <Text>{noResultsText}</Text>}
              </VStack>
            )}
            {numPages > 1 && (
              <Flex justifyContent="center" mt={{ base: 8, md: 20 }}>
                <Pagination
                  itemPadding="2"
                  displayAs="buttons"
                  paginationItems={paginationItems}
                  divideAt={perPage}
                  activePage={page}
                  numPages={numPages}
                  onPreviousSet={() => {
                    const prevPage = page - 1;
                    if (prevPage >= 1 && prevPage <= numPages) {
                      changePage(prevPage);
                    }
                  }}
                  onNextSet={() => {
                    const nextPage = page + 1;
                    if (nextPage >= 1 && nextPage <= numPages) {
                      changePage(nextPage);
                    }
                  }}
                  onItemClicked={(itemNumber) => {
                    changePage(itemNumber);
                  }}
                />
              </Flex>
            )}
          </Box>
        ) : (
          <Text>{noResultsText}</Text>
        )}
      </Wrapper>
    </Section>
  );
};

export default SearchListing;
