import React from 'react';
import { Button as ChakraButton, Center, Box } from '@chakra-ui/react';
import withLink from '@components/atoms/withLink';

/**
 * A helper function to set a style prop based on another.
 * This is needed to support scenarios where the button color or size
 * may be different between breakpoints.
 * @param {(object|array|string)} prop A Chakra UI style prop to copy.
 * @param {function} func A callback function to modify the values.
 * @returns {(object|array|string)} The copied style prop with modified values.
 */
function copyStyleProp(prop, func) {
  // Object of breakpoint values eg. { base: `white`, md: `blue` }
  // Uses a reducer function to loop through the object and modify each value.
  if (typeof prop === `object` && prop !== null) {
    return Object.entries(prop).reduce(
      (obj, [key, value]) => ({
        ...obj,
        [key]: func(value)
      }),
      {}
    );
  }

  // Array of breakpoint values eg. [`white`, `blue`]
  if (Array.isArray(prop)) {
    return prop.map(func);
  }

  // String value eg. `white`
  if (typeof prop === `string`) {
    return func(prop);
  }

  // Return original value if it doesn't match any type.
  return prop;
}

/**
 * A helper function to apply default style props to the icon.
 * This is used to automatically set the icon color and size based on the button styles.
 * @param {object} iconProps The icons existing props.
 * @param {(object|array|string)} iconColor The color prop set on the button.
 * @param {(object|array|string)} buttonColor The color prop set on the button.
 * @param {(object|array|string)} buttonSize The size prop set on the button.
 * @returns {object} The merged icon props.
 */
function getIconProps(iconProps, iconColor, buttonColor, buttonSize) {
  return {
    boxSize: copyStyleProp(buttonSize, (value) => {
      return value === `sm` ? 4 : 6;
    }),
    color:
      iconColor ||
      copyStyleProp(buttonColor, (value) => {
        return value === `white` || value === `primary.dark-blue`
          ? `primary.froneri-blue`
          : `white`;
      }),
    transition: `.3s`,
    sx: {
      '.chakra-button:hover &, .button-hover:hover &, .chakra-menu__menu-button:hover &':
        {
          color: `white`
        }
    },
    ...iconProps
  };
}

const ButtonIconCircle = ({
  icon,
  iconColor = `primary.dark-blue`,
  borderColor = `primary.froneri-blue`,
  hoverColor = `secondary.pink`,
  iconCircleProps = {}
}) => (
  <Center
    as="span"
    width="9"
    height="9"
    borderRadius="100%"
    borderWidth="1px"
    borderColor={borderColor}
    transition=".3s"
    sx={{
      '.chakra-button:hover &, .button-hover:hover &, .chakra-menu__menu-button:hover &':
        {
          borderColor: hoverColor,
          bg: hoverColor
        },
      '.chakra-button:hover & > svg, .button-hover:hover & > svg, .chakra-menu__menu-button:hover &  > svg':
        {
          color: `white`
        }
    }}
    {...iconCircleProps}>
    {React.cloneElement(icon, { color: iconColor, boxSize: 4, ...icon.props })}
  </Center>
);

const Button = ({
  children = null,
  variant = `primary`,
  size = { base: `sm`, md: `md` },
  color = `primary.dark-blue`,
  hoverColor = `secondary.pink`,
  icon = null,
  iconColor = null,
  iconBorderColor = null,
  leftIcon = null,
  rightIcon = null,
  iconCircleProps = null,
  _hover = {},
  ...props
}) => {
  let bgColor = null;
  let textColor = null;
  let px = null;
  let hoverStyles = null;
  let iconMod = null;
  let leftIconMod = null;
  let rightIconMod = null;

  /*
   * Setup primary button styles.
   */
  if (variant === `primary`) {
    bgColor = color;
    textColor = copyStyleProp(color, (value) => {
      return value === `white` ? `primary.dark-blue` : `white`;
    });
    hoverStyles = {
      bg: hoverColor,
      color: `white`,
      ..._hover
    };

    // Set the icon color and size based on button props.
    if (icon) {
      iconMod = React.cloneElement(
        icon,
        getIconProps(icon.props, iconColor, color, size)
      );

      // Assume the button should be square when this icon prop is used and reduce padding.
      px = `4 !important`;
    }

    if (leftIcon) {
      leftIconMod = React.cloneElement(
        leftIcon,
        getIconProps(leftIcon.props, iconColor, color, size)
      );
    }

    if (rightIcon) {
      rightIconMod = React.cloneElement(
        rightIcon,
        getIconProps(rightIcon.props, iconColor, color, size)
      );
    }
  }

  /*
   * Setup secondary button styles.
   */
  if (variant === `secondary`) {
    textColor = color;
    hoverStyles = {
      color: hoverColor,
      ..._hover
    };

    // Wrap the icon in circle.
    if (leftIcon) {
      leftIconMod = (
        <ButtonIconCircle
          icon={leftIcon}
          iconColor={iconColor || textColor}
          borderColor={iconBorderColor || textColor}
          hoverColor={hoverColor}
          iconCircleProps={iconCircleProps}
        />
      );
    }

    if (rightIcon) {
      rightIconMod = (
        <ButtonIconCircle
          icon={rightIcon}
          iconColor={iconColor || textColor}
          borderColor={iconBorderColor || textColor}
          hoverColor={hoverColor}
          iconCircleProps={iconCircleProps}
        />
      );
    }
  }

  return (
    <ChakraButton
      variant={variant}
      size={size}
      bg={bgColor}
      color={textColor}
      px={px}
      _hover={hoverStyles}
      iconSpacing=".625rem"
      leftIcon={leftIconMod || leftIcon}
      rightIcon={rightIconMod || rightIcon}
      sx={{
        '.card-link:hover &, .chakra-menu__menu-button:hover &': hoverStyles
      }}
      {...props}>
      {iconMod}
      {children && (
        <Box
          as="span"
          // Vertically center text with padding to accomodate for odd line height.
          pt={
            variant === `primary` || variant === `secondary` ? `.125em` : null
          }>
          {children}
        </Box>
      )}
    </ChakraButton>
  );
};

export default Button;

export const ButtonLink = withLink(Button);
