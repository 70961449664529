import React from 'react';
import { Icon } from '@chakra-ui/react';

const IceCream2Icon = (props) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.31428 8.33981C7.31428 5.77679 9.39202 3.69905 11.955 3.69905C14.5181 3.69905 16.5958 5.77679 16.5958 8.33982V16.6408H11.9613L11.955 16.6408L11.9487 16.6408H7.31428V8.33981ZM11.1055 18.3398H6.53688C6.02787 18.3398 5.61523 17.9272 5.61523 17.4182V8.33981C5.61523 4.83843 8.45367 2 11.955 2C15.4564 2 18.2949 4.83843 18.2949 8.33982V17.4182C18.2949 17.9272 17.8822 18.3398 17.3732 18.3398H12.8045V21.1505C12.8045 21.6197 12.4242 22 11.955 22C11.4858 22 11.1055 21.6197 11.1055 21.1505V18.3398ZM13.7851 5.6602C14.2543 5.6602 14.6347 6.04054 14.6347 6.50972V13.8301C14.6347 14.2993 14.2543 14.6796 13.7851 14.6796C13.316 14.6796 12.9356 14.2993 12.9356 13.8301V6.50972C12.9356 6.04054 13.316 5.6602 13.7851 5.6602ZM10.9745 10.1699C10.9745 9.70077 10.5941 9.32042 10.125 9.32042C9.65578 9.32042 9.27543 9.70077 9.27543 10.1699V13.8301C9.27543 14.2993 9.65578 14.6797 10.125 14.6797C10.5941 14.6797 10.9745 14.2993 10.9745 13.8301V10.1699Z"
    />
  </Icon>
);

export default IceCream2Icon;
