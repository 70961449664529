export const colors = {
  primary: {
    'dark-blue': `#001C49`,
    'froneri-blue': `#00BAD5`
  },
  secondary: {
    pink: `#C04377`,
    orange: `#F5A858`,
    green: `#80EDA3`,
    yellow: `#F2EC94`,
    'mid-blue': `#658999`,
    'mid-grey': `#3A6067`,
    'light-grey': `#F5F5F5`,
    'pale-blue': `#E2F6FA`,
    'transparent-white': `rgba(255, 255, 255, 0.3)`
  },
  brand: {
    oreo: `#0056A5`,
    extreme: `#00125A`,
    nuii: `#000000`,
    'haagen-dazs': `#5C1530`,
    outshine: `#275D38`,
    cadburys: `#4b0983`
  },
  gradient: {
    'overlay-dark': `#22293D`
  }
};
