import React from 'react';
import BurgerCrossIcon from '@components/atoms/icons/BurgerCrossIcon';
import BurgerDefaultIcon from '@components/atoms/icons/BurgerDefaultIcon';
import { Box } from '@chakra-ui/react';

const Burger = ({ isOpen = false, onClick, ...rest }) => {
  return (
    <Box
      backgroundColor="primary.dark-blue"
      w="34px"
      h="34px"
      borderRadius="xs"
      display="flex"
      justifyContent="center"
      alignItems="center"
      onClick={() => onClick?.()}>
      {isOpen === true ? (
        <BurgerCrossIcon h="4" {...rest} />
      ) : (
        <BurgerDefaultIcon h="4" {...rest} />
      )}
    </Box>
  );
};

export default Burger;
