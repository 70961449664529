import React from 'react';
import {
  IceCream1Icon,
  IceCream2Icon,
  IceCream3Icon,
  IceCream4Icon,
  IceCream5Icon,
  IceCream6Icon
} from '@components/atoms/icons';

const tabIconMap = {
  'ice-cream': <IceCream1Icon />,
  'ice-lolly': <IceCream2Icon />,
  'ice-lolly-swoosh': <IceCream3Icon />,
  'ice-lolly-bite': <IceCream4Icon />,
  'fruit-lolly': <IceCream5Icon />,
  'chocolate-ice-cream': <IceCream6Icon />
};

export const getTabIcon = (iconName) => {
  const convertedName = iconName.toLowerCase().replaceAll(` `, `-`);
  return tabIconMap[convertedName];
};
