const colorsWithDarkText = [`primary.froneri-blue`];

/**
 * A helper function that determines what text color to use based on a background color.
 * @param {string} bgColor The background color. This should be a Chakra UI color.
 * @returns {string} The text color to use.
 */
function getTextColor(bgColor) {
  return colorsWithDarkText.indexOf(bgColor) !== -1
    ? `primary.dark-blue`
    : `white`;
}

export default getTextColor;
