import React from 'react';
import { Box, AspectRatio, SimpleGrid } from '@chakra-ui/react';
import Image from '@components/atoms/Image';

const ImageFeature = ({
  images,
  color = `primary.froneri-blue`,
  reverse = false,
  ...props
}) => {
  // Single image layout.
  if (images.length === 1) {
    return (
      <Box
        position="relative"
        pt={{ md: `6.5%` }}
        pl={reverse ? { base: `14%`, md: `19%` } : null}
        pr={reverse ? null : { base: `14%`, md: `19%` }}
        _before={{
          content: `""`,
          display: `block`,
          position: `absolute`,
          top: { base: `8%`, md: 0 },
          left: reverse ? 0 : null,
          right: reverse ? null : 0,
          width: `42.5%`,
          height: 0,
          pt: `50%`,
          bg: color,
          borderRadius: `xs`
        }}
        {...props}>
        <AspectRatio
          width="100%"
          ratio="1"
          borderTopLeftRadius="xs"
          borderTopRightRadius="xs"
          borderBottomLeftRadius={reverse ? `lg` : `xs`}
          borderBottomRightRadius={reverse ? `xs` : `lg`}
          overflow="hidden">
          <Image image={images[0]} />
        </AspectRatio>
      </Box>
    );
  }

  // Two image layout.
  return (
    <SimpleGrid
      position="relative"
      spacing="2.5%"
      columns="2"
      direction={reverse ? `row-reverse` : `row`}
      _before={{
        content: `""`,
        display: `block`,
        position: `absolute`,
        top: `50%`,
        left: `50%`,
        transform: {
          base: `translate(-50%, -72.5%)`,
          md: reverse ? `translate(-30%, -65%)` : `translate(-70%, -65%)`
        },
        width: { base: `44%`, md: `50%` },
        height: 0,
        pt: { base: `44%`, md: `50%` },
        bg: color,
        borderRadius: `xs`
      }}
      {...props}>
      {images.slice(0, 2).map((image, index) => (
        <React.Fragment key={`image-${index + 1}`}>
          {reverse ? (
            <Box
              width="100%"
              pt={index === 0 ? `40%` : null}
              pl={{ base: index !== 0 ? `14%` : null, md: 0 }}>
              <AspectRatio
                ratio={index === 0 ? 3 / 4 : 2 / 3}
                borderTopLeftRadius="xs"
                borderTopRightRadius="xs"
                borderBottomLeftRadius={index === 0 ? `lg` : `xs`}
                borderBottomRightRadius="xs"
                overflow="hidden">
                <Image image={image} />
              </AspectRatio>
            </Box>
          ) : (
            <Box
              width="100%"
              pt={index !== 0 ? `40%` : null}
              pr={{ base: index === 0 ? `14%` : null, md: 0 }}>
              <AspectRatio
                ratio={index !== 0 ? 3 / 4 : 2 / 3}
                borderTopLeftRadius="xs"
                borderTopRightRadius="xs"
                borderBottomLeftRadius="xs"
                borderBottomRightRadius={index !== 0 ? `lg` : `xs`}
                overflow="hidden">
                <Image image={image} />
              </AspectRatio>
            </Box>
          )}
        </React.Fragment>
      ))}
    </SimpleGrid>
  );
};

export default ImageFeature;
