import React from 'react';
import { Icon } from '@chakra-ui/react';

const MinusIcon = (props) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 11.6998C3 11.2856 3.33579 10.9498 3.75 10.9498L20.25 10.9498C20.6642 10.9498 21 11.2856 21 11.6998C21 12.114 20.6642 12.4498 20.25 12.4498L3.75 12.4498C3.33579 12.4498 3 12.114 3 11.6998Z"
    />
  </Icon>
);

export default MinusIcon;
