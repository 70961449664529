import React, { useCallback } from 'react';
import {
  Link,
  Box,
  AccordionButton,
  AccordionPanel,
  AccordionItem,
  Button as ChakraButton
} from '@chakra-ui/react';
import { Link as GatsbyLink, navigate } from 'gatsby';
import ChevronDownIcon from '@components/atoms/icons/ChevronDownIcon';
import ChevronUpIcon from '@components/atoms/icons/ChevronUpIcon';
import ArrowRightIcon from '@components/atoms/icons/ArrowRightIcon';
import { stringToId } from '@helpers/stringFormatters';
import dataLayerPush from '@helpers/dataLayerPush';

const NavDropMobile = ({ menuItem }) => {
  const handleNavigate = useCallback((url, title) => {
    dataLayerPush({
      event: `nav_button_click`,
      interaction: {
        click_text: title,
        link_url: url,
        nav_level: 1
      }
    });

    navigate(url);
  }, []);

  return (
    <AccordionItem
      id={`nav-drop-mobile-for-${stringToId(menuItem.attributes.title)}`}
      border="none"
      color="#fff">
      {({ isExpanded }) => (
        <>
          <AccordionButton
            fontSize="2xl"
            fontFamily="heading"
            py="5px"
            // eslint-disable-next-line consistent-return
            onClick={() => {
              if (menuItem.attributes.children.data.length <= 1) {
                return handleNavigate(
                  menuItem.attributes.url,
                  menuItem.attributes.title
                );
              }
            }}>
            {menuItem.attributes.title}
            {menuItem.attributes.children.data.length > 1 &&
              (isExpanded === true ? (
                <ChevronUpIcon
                  ml="4px"
                  color="secondary.pink"
                  style={{ width: `1.25rem`, height: `1.25rem` }}
                />
              ) : (
                <ChevronDownIcon
                  ml="4px"
                  color="primary.froneri-blue"
                  style={{ width: `1.25rem`, height: `1.25rem` }}
                />
              ))}
          </AccordionButton>
          {menuItem.attributes.children.data.length > 1 && (
            <AccordionPanel>
              {menuItem.attributes.children.data.map((link, index) => (
                <Box
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${link.attributes.title.toLowerCase()}-${index}`}
                  mb="8px"
                  transition={`opacity 0.05s ease-in ${index * 0.05}s`}
                  opacity={isExpanded ? `1` : `0`}>
                  <Link
                    as={GatsbyLink}
                    to={link.attributes.url}
                    variant="header"
                    color="#fff"
                    fontSize="sm"
                    fontWeight="800"
                    onClick={
                      () =>
                        handleNavigate(
                          link.attributes.url,
                          link.attributes.title,
                          2
                        )
                      // eslint-disable-next-line react/jsx-curly-newline
                    }>
                    {link.attributes.title}
                  </Link>
                </Box>
              ))}
              <Link
                as={GatsbyLink}
                to={menuItem.attributes.url}
                transition={`opacity 0.05s ease-in ${
                  menuItem.attributes.children.data.length * 0.05
                }s`}>
                <ChakraButton
                  mt="5px"
                  backgroundColor="#fff"
                  color="primary.dark-blue"
                  rightIcon={
                    <ArrowRightIcon color="primary.froneri-blue" h="5" w="5" />
                  }>
                  {menuItem.attributes.title}
                </ChakraButton>
              </Link>
            </AccordionPanel>
          )}
        </>
      )}
    </AccordionItem>
  );
};

export default NavDropMobile;
