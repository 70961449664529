import React from 'react';
import { Icon } from '@chakra-ui/react';

const QuoteIcon = (props) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path d="M10.7707 2.45856C3.98049 2.63417 0 5.97075 0 14.5171V21.5415H10.361V11.2976H6.43902V11.0049C6.43902 8.6049 7.60976 7.31709 10.7707 7.20002V2.45856ZM24 2.45856C17.2098 2.63417 13.2293 5.97075 13.2293 14.5171V21.5415H23.5902V11.2976H19.6683V11.0049C19.6683 8.6049 20.839 7.31709 24 7.20002V2.45856Z" />
  </Icon>
);

export default QuoteIcon;
