import React from 'react';
import { Box, Heading, Text, SimpleGrid } from '@chakra-ui/react';
import CallToActions from '@components/molecules/CallToActions';
import ImageFeature from '@components/molecules/ImageFeature';
import Section from '@components/atoms/Section';
import Wrapper from '@components/atoms/Wrapper';
import getColorFromField from '@helpers/getColorFromField';

const MediaAndText = ({
  data: { title, tagline, text, images, ctas, alignment, color, brandColor }
}) => {
  const isImageRight = alignment === `Image Right`;
  const imageColor =
    getColorFromField(brandColor || color) || `primary.froneri-blue`;
  const textColor = getColorFromField(brandColor);

  return (
    <Section>
      <Wrapper>
        <SimpleGrid
          columns={{ md: 2 }}
          spacing={{ base: 10, md: 0 }}
          alignItems="center">
          {images && images.length > 0 && (
            <ImageFeature
              images={images}
              reverse={isImageRight}
              color={imageColor}
              order={{ base: 1, md: isImageRight ? 2 : 1 }}
            />
          )}
          <Box
            pl={isImageRight ? null : { md: 12, lg: 32 }}
            pr={isImageRight ? { md: 12, lg: 32 } : null}
            order="1">
            {tagline && (
              <Heading
                as="p"
                variant="detail"
                size="xs"
                color={textColor || `secondary.mid-grey`}>
                {tagline}
              </Heading>
            )}
            {title && (
              <Heading as="h2" size="2xl" color={textColor}>
                {title}
              </Heading>
            )}
            {text && (
              <Text color={textColor} mb="0">
                {text}
              </Text>
            )}
            {ctas && ctas.length > 0 && (
              <CallToActions
                ctas={ctas}
                primaryColor={textColor}
                secondaryColor={textColor}
                secondaryIconBorderColor={textColor || `primary.froneri-blue`}
                mt={{ base: 6, md: 8 }}
                dataLayerType="media_block"
              />
            )}
          </Box>
        </SimpleGrid>
      </Wrapper>
    </Section>
  );
};

export default MediaAndText;
