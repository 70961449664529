import { extendTheme } from '@chakra-ui/react';

export const erlenbacher = extendTheme({
  colors: {
    accent: {
      400: `#F9EF76`,
      500: `#F5E51B`
    }
  },
  fonts: {
    body: `Manrope, sans-serif`,
    heading: `Manrope, sans-serif`
  },
  styles: {
    global: {
      body: {
        color: `black`
      },
      '*, *::before, &::after': {
        borderColor: `black`
      }
    }
  },
  components: {
    FormLabel: {
      baseStyle: {
        textTransform: `uppercase`
      }
    },
    Input: {
      baseStyle: {
        field: {
          borderRadius: `none`
        }
      }
    },
    Select: {
      baseStyle: {
        field: {
          borderRadius: `none`
        }
      }
    },
    Textarea: {
      baseStyle: {
        borderRadius: `none`
      }
    },
    Button: {
      defaultProps: {
        colorScheme: `accent`
      },
      baseStyle: {
        borderRadius: `none`
      },
      variants: {
        solid: () => {
          return {
            color: `black`,
            _hover: {
              bg: `accent.400`
            }
          };
        }
      }
    }
  }
});
