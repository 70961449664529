import React from 'react';
import { Global } from '@emotion/react';
import ManropeVariable from '@assets/fonts/Manrope-Variable.woff2';
import RocGroteskBold from '@assets/fonts/Roc-Grotesk-Bold.otf';
import RocGroteskExtraBold from '@assets/fonts/Roc-Grotesk-ExtraBold.otf';

const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'Manrope';
        font-weight: 200 800;
        font-style: normal;
        font-display: swap;
        src: url(${ManropeVariable}) format('woff2');
      }
      @font-face {
        font-family: 'Roc Grotesk';
        font-weight: 700;
        font-style: normal;
        font-display: swap;
        src: url(${RocGroteskBold}) format('opentype');
      }
      @font-face {
        font-family: 'Roc Grotesk';
        font-weight: 900;
        font-style: normal;
        font-display: swap;
        src: url(${RocGroteskExtraBold}) format('opentype');
      }
    `}
  />
);

export default Fonts;
