import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { SimpleGrid, Box, Heading } from '@chakra-ui/react';
import Wrapper from '@components/atoms/Wrapper';
import Link from '@components/atoms/Link';
import { ExternalLinkIcon } from '@components/atoms/icons';
import dataLayerPush from '@helpers/dataLayerPush';

const ListStack = ({ data }) => {
  const { itemCollection, title, text } = data;

  const collectionData = useStaticQuery(graphql`
    query Query {
      allStrapiOffice {
        nodes {
          id
          name
          cta {
            title
            type
            link
          }
        }
      }
      allStrapiMarket {
        nodes {
          id
          name
          cta {
            title
            type
            link
          }
        }
      }
    }
  `);

  const reformated = Object.entries(collectionData).reduce((cur, [k, v]) => {
    return {
      ...cur,
      [k.toLowerCase()]: v.nodes
    };
  }, {});

  const nodes = reformated[`allstrapi${itemCollection}`];

  return (
    <Wrapper>
      <Heading as="h2" size="2xl">
        {title}
      </Heading>
      {text && (
        <Box as="p" pb={8}>
          {text}
        </Box>
      )}
      <SimpleGrid
        columns={{ base: 2, md: 3, lg: 5 }}
        spacing={3}
        pb={16}
        mb={16}
        borderBottom="1px solid"
        borderColor="secondary.pale-blue">
        {nodes &&
          nodes.map(({ id, cta, name }) => {
            return cta && cta.link && cta.title ? (
              <Box key={id}>
                <Link
                  href={cta.link}
                  variant="transparent"
                  className="card-cta"
                  color="primary.dark-blue"
                  _hover={{
                    color: `secondary.pink`,
                    textDecoration: `underline`
                  }}
                  fontSize="md"
                  fontWeight="bold"
                  onClick={() => {
                    dataLayerPush({
                      event: `cta_click`,
                      interaction: {
                        click_text: cta.title,
                        link_url: cta.link,
                        cta_type: cta.type
                      }
                    });
                  }}>
                  {name}
                  {cta.type === `external` && <ExternalLinkIcon ml="1" />}
                </Link>
              </Box>
            ) : (
              <Box key={id} fontWeight="bold" color="primary.dark-blue">
                {name}
              </Box>
            );
          })}
      </SimpleGrid>
    </Wrapper>
  );
};

export default ListStack;
