import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { Fonts } from '@components';
import { theme } from '@styles/theme';
import { erlenbacher } from '@styles/erlenbacher';

const themes = {
  froneri: theme,
  erlenbacher
};

export const wrapPageElement = ({ element, props }) => {
  const {
    pageContext: { themeName }
  } = props;

  return (
    <ChakraProvider
      theme={
        typeof themeName !== `undefined` ? themes[themeName] : themes.froneri
      }>
      <Fonts />
      {element}
    </ChakraProvider>
  );
};
