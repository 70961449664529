import { Icon, Box } from '@chakra-ui/react';
import React from 'react';

const MapMarkerImage = ({ onClick = () => {}, ...rest }) => {
  return (
    <Box
      width="44px"
      height="50px"
      cursor="pointer"
      onClick={onClick}
      pointerEvents="all"
      ml="6px"
      mt="3px"
      borderRadius="50%"
      {...rest}>
      <Icon
        width="60px"
        height="55px"
        viewBox="0 0 60 55"
        fill="none"
        overflow="visible"
        pointerEvents="none"
        ml="-8px"
        mt="-3px">
        <g opacity="0.6" filter="url(#filter0_f_2352_62120)">
          <ellipse cx="27" cy="50" rx="15" ry="4" fill="#001C49" />
        </g>
        <g filter="url(#filter1_d_2352_62120)">
          <path
            d="M11 16.7541C11 28.4847 21.8488 40.7267 25.6798 44.6839C26.4133 45.4415 27.5867 45.4415 28.3202 44.6839C32.1512 40.7267 43 28.4847 43 16.7541C43 8.60563 35.8366 0 27 0C18.1634 0 11 8.60563 11 16.7541Z"
            fill="#001C49"
          />
          <path
            d="M11.5 16.7541C11.5 22.478 14.1526 28.3734 17.3253 33.3659C20.4912 38.3478 24.1359 42.3703 26.039 44.3361C26.576 44.8908 27.424 44.8908 27.961 44.3361C29.8641 42.3703 33.5088 38.3478 36.6747 33.3659C39.8474 28.3734 42.5 22.478 42.5 16.7541C42.5 12.8206 40.7672 8.7489 37.9445 5.65858C35.124 2.57062 31.2509 0.5 27 0.5C22.7491 0.5 18.876 2.57062 16.0555 5.65858C13.2328 8.7489 11.5 12.8206 11.5 16.7541Z"
            stroke="white"
          />
        </g>
        <path
          d="M20 14.6L27 9L34 14.6V23.4C34 23.8243 33.8361 24.2313 33.5444 24.5314C33.2527 24.8314 32.857 25 32.4444 25H21.5556C21.143 25 20.7473 24.8314 20.4556 24.5314C20.1639 24.2313 20 23.8243 20 23.4V14.6Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25 25V17H29V25"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <defs>
          <filter
            id="filter0_f_2352_62120"
            x="0"
            y="34"
            width="54"
            height="32"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="6"
              result="effect1_foregroundBlur_2352_62120"
            />
          </filter>
          <filter
            id="filter1_d_2352_62120"
            x="3"
            y="0"
            width="48"
            height="63.2521"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="10" />
            <feGaussianBlur stdDeviation="4" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_2352_62120"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_2352_62120"
              result="shape"
            />
          </filter>
        </defs>
      </Icon>
    </Box>
  );
};

export default MapMarkerImage;
