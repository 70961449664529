import React from 'react';
import { Icon } from '@chakra-ui/react';

const FilterIcon = (props) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 4.25C6.41421 4.25 6.75 4.58579 6.75 5V5.9075C7.89401 6.22547 8.75 7.25283 8.75 8.5C8.75 9.74717 7.89401 10.7745 6.75 11.0925V19C6.75 19.4142 6.41421 19.75 6 19.75C5.58579 19.75 5.25 19.4142 5.25 19V11.0925C4.10599 10.7745 3.25 9.74717 3.25 8.5C3.25 7.25283 4.10599 6.22547 5.25 5.9075V5C5.25 4.58579 5.58579 4.25 6 4.25ZM18 4.25C18.4142 4.25 18.75 4.58579 18.75 5V7.46305C19.894 7.78103 20.75 8.80839 20.75 10.0556C20.75 11.3027 19.894 12.3301 18.75 12.6481V19C18.75 19.4142 18.4142 19.75 18 19.75C17.5858 19.75 17.25 19.4142 17.25 19V12.6481C16.106 12.3301 15.25 11.3027 15.25 10.0556C15.25 8.80839 16.106 7.78103 17.25 7.46305V5C17.25 4.58579 17.5858 4.25 18 4.25ZM12 4.25009C12.4142 4.25009 12.75 4.58588 12.75 5.00009V12.9074C13.894 13.2253 14.75 14.2527 14.75 15.4999C14.75 16.747 13.894 17.7744 12.75 18.0924V18.9997C12.75 19.4139 12.4142 19.7497 12 19.7497C11.5858 19.7497 11.25 19.4139 11.25 18.9997V18.0924C10.106 17.7744 9.25 16.747 9.25 15.4999C9.25 14.2527 10.106 13.2253 11.25 12.9074V5.00009C11.25 4.58588 11.5858 4.25009 12 4.25009ZM6 7.30556C5.28959 7.30556 4.75 7.8601 4.75 8.5C4.75 9.1399 5.28959 9.69444 6 9.69444C6.71041 9.69444 7.25 9.1399 7.25 8.5C7.25 7.8601 6.71041 7.30556 6 7.30556ZM18 8.86111C17.2896 8.86111 16.75 9.41566 16.75 10.0556C16.75 10.6955 17.2896 11.25 18 11.25C18.7104 11.25 19.25 10.6955 19.25 10.0556C19.25 9.41566 18.7104 8.86111 18 8.86111ZM12 14.3054C11.2896 14.3054 10.75 14.86 10.75 15.4999C10.75 16.1398 11.2896 16.6943 12 16.6943C12.7104 16.6943 13.25 16.1398 13.25 15.4999C13.25 14.86 12.7104 14.3054 12 14.3054Z"
    />
  </Icon>
);

export default FilterIcon;
