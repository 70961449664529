import React from 'react';
import { Flex } from '@chakra-ui/react';
import ShareOnSocial from 'react-share-on-social';
import { ArrowRightIcon } from '@components/atoms/icons';
import Button from '@components/atoms/Button';
import favicon from '../../../static/favicon.png';

const SharePageBlock = ({ buttonText, link, metaTitle, metaDescription }) => {
  return (
    <Flex justifyContent="center" alignItems="center" py="4">
      <ShareOnSocial
        textToShare=""
        link={link}
        linkTitle={metaTitle}
        linkMetaDesc={metaDescription}
        linkFavicon={favicon}
        noReferer>
        <Button
          variant="secondary"
          iconBorderColor="primary.froneri-blue"
          rightIcon={<ArrowRightIcon />}>
          {buttonText}
        </Button>
      </ShareOnSocial>
    </Flex>
  );
};

export default SharePageBlock;
